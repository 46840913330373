.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.innerContainer {
  border-radius: 10px;
  background: var(--white);
  padding: 59px 2rem;
  width: 526px;
}

.innerContainer > h4 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 12px;
}

.innerContainer > p {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.quizDetails {
  display: flex;
  align-items: center;
  margin: 1.5rem 0;
  border-radius: 8px;
  background: var(--sideNavBackground);
}

.quizDetails > span {
  flex: 1;
  padding: 18px;
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.quizDetails > span:nth-child(1) {
  border-right: 1px solid var(--greyWhite);
}

.innerContainer > button {
  width: 171px;
  margin: auto;
}

.warning {
  color: var(--red2);
  text-align: center;
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  margin-top: 1.5rem;
}

.warning > span {
  color: var(--grey);
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    min-height: 90vh;
  }

  .innerContainer {
    padding: 26px 1rem;
    width: 70%;
  }

  .innerContainer > h4 {
    font-size: 18px;
    line-height: 27px;
  }

  .innerContainer > p {
    font-size: 14px;
    line-height: 23px;
  }

  .quizDetails > span {
    padding: 16px;
    font-size: 12px;
    line-height: 23px;
  }

  .innerContainer > button {
    width: 154px;
  }

  .warning {
    font-size: 12px;
    line-height: 23px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    min-height: 70vh;
  }

  .innerContainer {
    padding: 26px 1rem;
    width: 100%;
  }

  .innerContainer > h4 {
    font-size: 18px;
    line-height: 27px;
  }

  .innerContainer > p {
    font-size: 14px;
    line-height: 23px;
  }

  .quizDetails > span {
    padding: 16px;
    font-size: 12px;
    line-height: 23px;
  }

  .innerContainer > button {
    width: 154px;
  }
  .warning {
    font-size: 12px;
    line-height: 23px;
  }
}

.questionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0;
}

.questionHeader > span:nth-child(1) {
  color: var(--grey);
  font-family: Archivo;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.questionHeader > span:nth-child(2) {
  color: var(--grey);
  font-family: Archivo;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}

.questionHeader > span:nth-child(2) > span {
  color: var(--red3);
}

.questionHeader > span:nth-child(3) {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--purple);
  font-family: Archivo;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  cursor: pointer;
}

.questionHeader > span:nth-child(3) > svg {
  width: 24px;
  height: 24px;
}

.questionCard {
  padding: 1.5rem 2rem;
  border-radius: 10px;
  background: #fff;
}

.question {
  padding: 1rem 0;
  border-bottom: 1px solid #d8d8d8;
}

.indexSection {
  padding: 3px 20px;
  border-radius: 5px;
  background: var(--greyWhite);
  display: inline;
  color: var(--grey);
  font-family: Archivo;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
}

.question > h4 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.question > span:nth-child(3) {
  color: var(--timerGreen);
  font-family: Archivo;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
}

.optionSection {
  padding: 1rem 0;
}

.anseer {
  display: flex;
  align-items: flex-start;
  gap: 32px;
  margin-bottom: 12px;
  border-radius: 8px;
  border: 0.75px solid var(--sectionBorder);
  padding: 20px 1rem;
}

.buttonSection {
  display: flex;
  align-items: center;
  gap: 36px;
  justify-content: flex-end;
  margin-top: 22px;
}

.buttonSection > button {
  max-width: 132px;
}

.modalContainer {
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.modalContainer > img {
  width: 280px;
  height: 280px;
  margin-bottom: 24px;
}

.modalContainer > h4 {
  color: var(--grey);
  text-align: center;
  font-family: Archivo;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
}

.modalContainer > p {
  color: var(--modalGreen);
  text-align: center;
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-top: 1rem;
}

.modalContainer > p > span {
  color: var(--purple);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  cursor: pointer;
}

.modalButtonSection {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  width: 100%;
}

.modalButtonSection > button {
  max-width: 190px;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .questionHeader {
    margin: 1rem 0;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .questionHeader > span:nth-child(1) {
    font-size: 16px;
    line-height: 25px;
    flex-basis: 100%;
  }

  .questionHeader > span:nth-child(2) {
    font-size: 14px;
    line-height: 23px;
  }

  .questionHeader > span:nth-child(3) {
    font-size: 10px;
    line-height: 18px;
  }

  .questionHeader > span:nth-child(3) > svg {
    width: 12px;
    height: 12px;
  }

  .questionCard {
    padding: 1.5rem 1rem;
  }

  .question {
    padding: 1rem 0;
  }

  .indexSection {
    padding: 3px 15px;
    font-size: 12px;
    line-height: 23px;
  }

  .question > h4 {
    font-size: 14px;
    line-height: 23px;
  }

  .question > span:nth-child(3) {
    font-size: 10px;
    line-height: 18px;
  }

  .anseer {
    gap: 30px;
    padding: 20px 1rem;
  }

  .buttonSection {
    margin-top: 16px;
  }

  .buttonSection > button {
    max-width: 116px;
  }
}

@media screen and (max-width: 767px) {
  .questionHeader {
    margin: 1rem 0;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .questionHeader > span:nth-child(1) {
    font-size: 16px;
    line-height: 25px;
    flex-basis: 100%;
  }

  .questionHeader > span:nth-child(2) {
    font-size: 14px;
    line-height: 23px;
  }

  .questionHeader > span:nth-child(3) {
    font-size: 10px;
    line-height: 18px;
  }

  .questionHeader > span:nth-child(3) > svg {
    width: 12px;
    height: 12px;
  }

  .questionCard {
    padding: 1.5rem 1rem;
  }

  .question {
    padding: 1rem 0;
  }

  .indexSection {
    padding: 3px 15px;
    font-size: 12px;
    line-height: 23px;
  }

  .question > h4 {
    font-size: 14px;
    line-height: 23px;
  }

  .question > span:nth-child(3) {
    font-size: 10px;
    line-height: 18px;
  }

  .anseer {
    gap: 30px;
    padding: 20px 1rem;
  }

  .buttonSection {
    margin: 16px 0;
  }

  .buttonSection > button {
    max-width: 116px;
  }
}

.dropContainer {
  border-radius: 5px;
  border: 1px dashed var(--borderPurple);
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-direction: column;
  color: var(--purple);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-top: 0.5rem;
}

.dropContainer > span:nth-child(3) {
  cursor: pointer;
  font-weight: 500;
}

.dropContainer > span:nth-child(3) > input {
  display: none;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .dropContainer {
    border: 1px dashed var(--borderPurple);
    gap: 0.5rem;
    font-size: 12px;
    line-height: 23px;
  }
}

@media screen and (max-width: 767px) {
  .dropContainer {
    border: 1px dashed var(--borderPurple);
    gap: 0.5rem;
    font-size: 12px;
    line-height: 23px;
  }
}

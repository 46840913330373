.container {
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}

.noNotification {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-family: Archivo;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
}

.header > span:nth-child(1) {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
}

.header > span:nth-child(2) {
  font-family: Archivo;
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
}

.notificationsList {
  padding: 8px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.notificationsList::-webkit-scrollbar {
  display: none;
}

.notification {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-bottom: 1px solid #e8e8e8;
  padding: 0.5rem;
}

.notification > span:nth-child(1) {
  width: 8px;
  height: 8px;
  display: block;
  border-radius: 50%;
}

.notification > span:nth-child(2) {
  flex: 1;
}

.notification > span:nth-child(2) > p:nth-child(1) {
  font-family: Archivo;
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: var(--grey);
}

.notification > span:nth-child(2) > div {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: Archivo;
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: var(--grey3);
  margin-top: 0.3rem;
}

.lessonNav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 30px;
  padding: 22px 0;
}

.lessonNav > div {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  color: var(--grey);
  font-family: Archivo;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.bookmark {
  padding: 1.5rem 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  color: var(--black);
}

.bookmark > div {
  border-radius: 4px;
  background: #fff;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  user-select: none;
}

.bookmark > div:nth-child(1) {
  padding: 10px;
  border: 2px solid var(--black);
}

.bookmark > div:nth-child(2) {
  padding: 10px 30px;
}

.bookmark > div:nth-child(2):hover,
.activeCompleted {
  border: 2px solid var(--purple);
  color: var(--purple);
  height: 100%;
  overflow-y: auto;
}

.nonCompleted {
  border: 2px solid var(--black);
}

.paragraphSection {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.paragraphSection > p {
  margin-bottom: 1rem;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .lessonNav {
    gap: 24px;
    padding: 20px 0;
  }

  .lessonNav > div {
    gap: 8px;
    font-size: 12px;
    line-height: 23px;
  }

  .bookmark {
    font-size: 10px;
    line-height: 18px;
  }

  .bookmark > div:nth-child(2) {
    padding: 10px 20px;
  }

  .paragraphSection {
    font-size: 14px;
    line-height: 23px;
  }
}

@media screen and (max-width: 767px) {
  .lessonNav {
    gap: 24px;
    padding: 20px 0;
  }

  .lessonNav > div {
    gap: 8px;
    font-size: 12px;
    line-height: 23px;
  }

  .bookmark {
    font-size: 10px;
    line-height: 18px;
  }

  .bookmark > div:nth-child(2) {
    padding: 10px 20px;
  }

  .paragraphSection {
    font-size: 14px;
    line-height: 23px;
  }
}

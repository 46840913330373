.container {
  margin-top: 10px;
  padding: 28px;
  display: flex;
  align-items: center;
  gap: 38px;
  border-radius: 10px;
  background: var(--white);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}

.container > div:nth-child(1) {
  flex: 1;
}

.container > button:nth-child(2) {
  width: 192px;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    margin-top: 24px;
    padding: 24px 16px;
    gap: 16px;
    flex-direction: column;
    align-items: flex-start;
  }

  .container > div:nth-child(1) {
    flex: 1;
  }

  .container > button:nth-child(2) {
    width: 192px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    margin-top: 24px;
    padding: 24px 16px;
    gap: 16px;
    flex-direction: column;
    align-items: flex-start;
  }

  .container > div:nth-child(1) {
    flex: 1;
  }

  .container > button:nth-child(2) {
    width: 192px;
  }
}

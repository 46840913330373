.container {
  min-height: 100vh;
  background-color: var(--cream);
  position: relative;
}

.alt {
  background-color: var(--sideNavBackground);
}

.body {
  min-height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.children {
  min-height: 200px;
  width: 520px;
  border: 1px solid var(--progressBarIndicatorYellow);
  background-color: var(--white);
  padding: 40px;
  border-radius: 10px;
}

.header {
  padding: 26px 80px;
  height: 100px;
}

.container > svg {
  z-index: 0;
}

.container > svg:nth-child(3) {
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  position: absolute;
}

.container > svg:nth-child(4) {
  bottom: 0;
  left: 149px;
  position: absolute;
}

.container > svg:nth-child(5) {
  top: 0;
  bottom: 0;
  margin: auto;
  right: 0;
  position: absolute;
}

.container > svg:nth-child(6) {
  top: 0;
  right: 149px;
  position: absolute;
}

@media screen and (max-width: 767px) {
  .container {
    height: auto;
    background-color: var(--white);
  }

  .body {
    height: calc(100vh - 50px);
    display: flex;
    align-items: flex-start;
  }

  .children {
    min-height: 100%;
    height: 100%;
    width: 100%;
    border: none;
    background-color: var(--white);
    padding: 24px 1rem;
    border-radius: 0px;
  }

  .header {
    padding: 8px 16px;
    height: 50px;
  }

  .header > img {
    width: 35px;
    height: 33px;
  }

  .container > svg {
    display: none;
  }
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    height: auto;
    background-color: var(--white);
  }

  .body {
    height: calc(100vh - 50px);
    display: flex;
    align-items: flex-start;
  }

  .children {
    min-height: 100%;
    height: 100%;
    width: 100%;
    border: none;
    background-color: var(--white);
    padding: 24px 1rem;
    border-radius: 0px;
  }

  .header {
    padding: 8px 16px;
    height: 50px;
  }

  .header > img {
    width: 35px;
    height: 33px;
  }

  .container > svg {
    display: none;
  }
}

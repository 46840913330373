.container {
  margin: 40px 1.5rem;
}

.modalContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1.5rem;
}

.modalContainer > img {
  width: 280px;
  height: 280px;
}

.modalContainer > h4 {
  color: var(--grey);
  text-align: center;
  font-family: Archivo;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
}

.modalContainer > p {
  color: var(--grey3);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  width: 330px;
  text-align: center;
}

.socialIcons {
  display: flex;
  align-items: center;
  gap: 40px;
}

.socialIcons > span {
  width: 64px;
  height: 64px;
  background-color: var(--purple);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.copySection {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  border: 1px solid var(--purple);
  user-select: all;
}

.copySection > p::selection {
  background: var(--purple);
}

.copySection > span:nth-child(2) {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  user-select: none;
}

.modalContainer > button {
  width: 104px;
  margin-top: 40px;
}

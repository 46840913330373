.container > h4 {
  color: var(--black2);
  font-family: Archivo;
  font-size: 31px;
  font-style: normal;
  font-weight: 600;
  line-height: 42px;
  margin-bottom: 5px;
}

.container > div {
  padding: 30px;
  border-radius: 10px;
  background: var(--white);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}

.container > div > p:nth-child(1) {
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  color: var(--grey3);
}

.container > div > p:nth-child(2) {
  color: var(--purple);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  margin-top: 10px;
  text-align: right;
  cursor: pointer;
}

.sliced {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow-y: hidden;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container > div {
    padding: 24px;
  }

  .container > h4 {
    font-size: 20px;
    line-height: 30px;
  }
  .container > div > p:nth-child(1) {
    font-size: 14px;
    line-height: 23px;
  }

  .container > div > p:nth-child(2) {
    font-size: 13px;
    line-height: 21px;
  }
}

@media screen and (max-width: 767px) {
  .container > div {
    padding: 24px;
  }

  .container > h4 {
    font-size: 20px;
    line-height: 30px;
  }
  .container > div > p:nth-child(1) {
    font-size: 14px;
    line-height: 23px;
  }

  .container > div > p:nth-child(2) {
    font-size: 13px;
    line-height: 21px;
  }
}

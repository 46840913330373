.container {
  margin-block: 1.5rem;
}

.container > div {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 10px;
  animation: appear 0.3s ease-in-out;
}

.condition > div > svg {
  animation: appear 0.3s ease-in-out;
}

.container > div > span {
  font-family: Archivo;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  transition: all 0.3s ease-in-out;
}

.condition > span {
  color: var(--modalGreen);
}

.invalid > span {
  color: var(--grey3);
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.container {
  padding: 24px;
  background: var(--white);
}

@media screen and (max-width: 1023px) {
  .container {
    padding: 16px;
    background: var(--white);
  }
}

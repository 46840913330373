.container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid var(sectionsNav);
  background: var(--white);
  padding: 40px 2rem;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}

.innerContainer {
  width: 70%;
}

.innerContainer > h4 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 39px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
  margin-bottom: 1.5rem;
  text-align: center;
}

.faq {
  margin-bottom: 0.5rem;
}

.viewMore > button {
  width: 146px;
  margin-left: auto;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    border: none;
    background: none;
    padding: 0;
  }

  .innerContainer {
    width: 100%;
  }

  .innerContainer > h4 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 0.75rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 1023px) {
  .container {
    border: none;
    background: none;
    padding: 0;
  }
  .innerContainer {
    width: 100%;
  }

  .innerContainer > h4 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 0.75rem;
  }
}

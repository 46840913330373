.container {
  height: calc(100vh - 100px);
  display: flex;
  align-items: stretch;
  flex-direction: column;
}

.breadCrumb {
  padding: 24px;
  background: var(--white);
  height: 74px;
  box-sizing: border-box;
}

.bodyMain {
  padding-left: 24px;
  display: flex;
  align-items: flex-start;
  height: calc(100vh - 174px);
}

.coursesList {
  height: 100%;
  width: 246px;
}

.coursesActions {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  padding: 24px;
  overflow-y: auto;
}

.topSection {
  flex: 1;
}

.bottomSection {
  height: 10vh;
}

.mobileHeader {
  display: none;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    height: auto;
  }

  .breadCrumb {
    display: none;
  }

  .bodyMain {
    padding-left: 0px;
    height: auto;
  }

  .coursesList {
    display: none;
  }

  .coursesActions {
    padding: 16px;
  }

  .topSection {
    flex: 1;
    min-height: 50vh;
  }

  .bottomSection {
    height: auto;
  }

  .mobileHeader {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .container {
    height: auto;
  }

  .breadCrumb {
    display: none;
  }

  .bodyMain {
    padding-left: 0px;
    height: auto;
  }

  .coursesList {
    display: none;
  }

  .coursesActions {
    padding: 16px;
  }

  .topSection {
    flex: 1;
    min-height: 50vh;
  }

  .bottomSection {
    height: auto;
  }

  .mobileHeader {
    display: block;
  }
}

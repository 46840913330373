.container {
  border-radius: 0px 0px 10px 10px;
  background: var(--white);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  padding: 48px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.header > h4 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 37px;
}

.header > div:nth-child(2) {
  width: 192px;
}

.tableHeader {
  background: var(--veryVeryLightPurple);
  color: var(--grey);
  font-family: Archivo;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}

.tableBody:not(:last-child) {
  border-bottom: 1px solid var(--getStartedBorder);
}

.tableBody {
  color: var(--grey);
  font-family: Archivo;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 63.5px;
}

.tableHeader,
.tableBody {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 34px;
}

.tableHeader > span:not(span:nth-child(2)),
.tableBody > span:not(span:nth-child(2)) {
  flex: 1;
}

.tableHeader > span:nth-child(2),
.tableBody > span:nth-child(2) {
  flex: 4;
}

.weightedGrade {
  text-align: right;
  color: var(--grey);
  font-family: Archivo;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin-top: 2rem;
}

.noGrades {
  text-align: right;
  color: var(--grey);
  font-family: Archivo;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin-top: 2rem;
  text-align: center;
  padding: 1rem 0;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 16px;
  }

  .header {
    margin-bottom: 20px;
  }

  .header > h4 {
    font-size: 12px;
    line-height: 23px;
  }

  .header > div:nth-child(2) {
    width: 170px;
  }

  .tableHeader {
    font-size: 14px;
    line-height: 23px;
  }

  .tableBody {
    font-size: 14px;
    line-height: 63.5px;
  }

  .tableHeader,
  .tableBody {
    padding: 10px 16px;
  }

  .tableHeader > span:not(span:nth-child(2)),
  .tableBody > span:not(span:nth-child(2)) {
    flex: 1;
  }

  .tableHeader > span:nth-child(2),
  .tableBody > span:nth-child(2) {
    flex: 4;
  }

  .weightedGrade {
    font-size: 16px;
    line-height: 25px;
    margin-top: 1rem;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 16px;
  }

  .header {
    margin-bottom: 20px;
  }

  .header > h4 {
    font-size: 12px;
    line-height: 23px;
  }

  .header > div:nth-child(2) {
    width: 170px;
  }

  .tableHeader {
    font-size: 14px;
    line-height: 23px;
  }

  .tableBody {
    font-size: 14px;
    line-height: 63.5px;
  }

  .tableHeader,
  .tableBody {
    padding: 10px 16px;
  }

  .tableHeader > span:not(span:nth-child(2)),
  .tableBody > span:not(span:nth-child(2)) {
    flex: 1;
  }

  .tableHeader > span:nth-child(2),
  .tableBody > span:nth-child(2) {
    flex: 4;
  }

  .weightedGrade {
    font-size: 16px;
    line-height: 25px;
    margin-top: 1rem;
  }
}

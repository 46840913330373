.container {
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
}

.container > div:nth-child(1) {
  width: 336px;
}

.container > div:nth-child(1) > h4 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 0.5rem;
}

.container > div:nth-child(1) > p {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.container > div:nth-child(2) {
  flex: 1;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    flex-direction: column;
  }

  .container > div {
    width: 100%;
  }

  .container > div:nth-child(1) > h4 {
    font-size: 18px;
    line-height: 27px;
  }

  .container > div:nth-child(1) > p {
    font-size: 14px;
    line-height: 23px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    flex-direction: column;
  }

  .container > div {
    width: 100%;
  }

  .container > div:nth-child(1) > h4 {
    font-size: 18px;
    line-height: 27px;
  }

  .container > div:nth-child(1) > p {
    font-size: 14px;
    line-height: 23px;
  }
}

.container {
  padding: 80px;
  background: #fff;
}

.bodyMain {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}

.step > h4 {
  color: var(--grey);
  text-align: center;
  font-family: Archivo;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 1rem;
}

.step > p {
  color: var(--grey);
  text-align: center;
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

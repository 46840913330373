.container,
.noCompleted {
  padding: 40px 2rem;
  border-radius: 10px;
  background: var(--white);
}

.noCompleted {
  color: var(--grey);
  font-family: Archivo;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
}

.course {
  display: flex;
  align-items: stretch;
  gap: 1.5rem;
  padding: 1.5rem;
  border-radius: 10px;
  border: 1px solid var(--sectionBorder);
  margin-bottom: 1rem;
}

.course > img {
  border-radius: 4px;
  width: 257.25px;
  height: 100%;
  object-fit: cover;
}

.course > div:nth-child(2) {
  flex: 1;
}

.course > div:nth-child(2) > div:nth-child(1) {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.course > div:nth-child(2) > div:nth-child(1) > span:nth-child(1) {
  color: var(--grey);
  font-family: Archivo;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.course > div:nth-child(2) > div:nth-child(1) > span:nth-child(2) {
  padding: 5px 20px;
  background: #ffd029;
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  border-radius: 50px;
}

.course > div:nth-child(2) > p:nth-child(2) {
  margin-top: 0.5rem;
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.course > div:nth-child(2) > div:nth-child(3) {
  margin-top: 1.5rem;
}

.course > div:nth-child(3) {
  width: 222px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.finishedDate {
  color: var(--grey3);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--grey);
  font-family: Archivo;
  font-size: 39px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
  margin-bottom: 40px;
}

.header > svg {
  cursor: pointer;
}

.rateSection > p {
  color: var(--grey);
  font-family: Archivo;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}

.rateSection > div {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

.rateSection > div > svg {
  cursor: pointer;
}

.textareaSection {
  margin-top: 40px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: calc(40px - 1rem);
}

.footer > p {
  color: var(--grey3);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  width: 301px;
}

.footer > button {
  width: 153px;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 1rem;
  }

  .course {
    gap: 1rem;
    padding: 1rem;
    flex-direction: column;
  }

  .course > img {
    width: 100%;
    height: 170.655px;
  }

  .course > div:nth-child(2) > div:nth-child(1) > span:nth-child(1) {
    font-size: 18px;
    line-height: 27px;
  }

  .course > div:nth-child(2) > div:nth-child(1) > span:nth-child(2) {
    font-size: 14px;
    line-height: 23px;
  }

  .course > div:nth-child(2) > p:nth-child(2) {
    font-size: 12px;
    line-height: 23px;
  }

  .course > div:nth-child(3) {
    width: 180px;
    gap: 16px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 1rem;
  }

  .course {
    gap: 1rem;
    padding: 1rem;
    flex-direction: column;
  }

  .course > img {
    width: 100%;
    height: 170.655px;
  }

  .course > div:nth-child(2) > div:nth-child(1) > span:nth-child(1) {
    font-size: 18px;
    line-height: 27px;
  }

  .course > div:nth-child(2) > div:nth-child(1) > span:nth-child(2) {
    font-size: 14px;
    line-height: 23px;
  }

  .course > div:nth-child(2) > p:nth-child(2),
  .noCompleted {
    font-size: 12px;
    line-height: 23px;
  }

  .course > div:nth-child(3) {
    width: 180px;
    gap: 16px;
  }
}

.container {
  padding: 40px 32px;
  border-radius: 10px;
  border: 1px solid var(--greyWhite);
  background: var(--white);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  margin-top: 20px;
}

.container > h4 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 40px;
}

.progressReport {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}

.progressReport > p {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.progressReport > p > span {
  color: var(--red2);
}

.progressReport > div {
  width: 157px;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 24px 16px;
  }

  .container > h4 {
    font-size: 18px;
    line-height: 27px;
  }

  .progressReport {
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
  }

  .progressReport > p {
    font-size: 12px;
    line-height: 23px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 24px 16px;
  }

  .container > h4 {
    font-size: 18px;
    line-height: 27px;
  }

  .progressReport {
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
  }

  .progressReport > p {
    font-size: 12px;
    line-height: 23px;
  }
}

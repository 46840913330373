.container {
  padding: 72px 68px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
}

.header > h4:nth-child(1) {
  color: var(--grey);
  font-family: Archivo;
  font-size: 31px;
  font-style: normal;
  font-weight: 600;
  line-height: 42px;
}

.header > span:nth-child(2) {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--purple);
  font-family: Archivo;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  cursor: pointer;
}

.header > span:nth-child(2) > svg {
  width: 24px;
  height: 24px;
}

.indexSection {
  padding: 3px 20px;
  border-radius: 5px;
  background: var(--greyWhite);
  display: inline;
  color: var(--grey);
  font-family: Archivo;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
}

.question {
  padding: 1.5rem;
  border-radius: 10px;
  border: 1px solid var(--sectionBorder);
  margin-bottom: 24px;
}

.question > h4 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.question > span:nth-child(3) {
  color: var(--timerGreen);
  font-family: Archivo;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
}

.optionSection {
  padding: 1rem 0;
}

.anseer {
  display: flex;
  align-items: flex-start;
  gap: 32px;
  margin-bottom: 12px;
  border-radius: 8px;
  padding: 16px 28px;
  background: var(--bodyBackgroundColor);
  color: var(--grey);
  font-family: Archivo;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
}

.isCorrect {
  background: var(--isCorrectGreen);
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 1rem;
  }

  .header {
    margin-bottom: 24px;
  }

  .header > h4:nth-child(1) {
    font-size: 20px;
    line-height: 30px;
  }

  .header > span:nth-child(2) > span:nth-child(1) {
    display: none;
  }

  .header > span:nth-child(2) > svg {
    width: 20px;
    height: 20px;
  }

  .question {
    padding: 1rem;
    margin-bottom: 16px;
  }

  .question > h4 {
    font-size: 14px;
    line-height: 23px;
  }

  .question > span:nth-child(3) {
    font-size: 10px;
    line-height: 18px;
  }

  .optionSection {
    padding: 16px 0;
  }

  .anseer {
    padding: 16px;
    background: var(--bodyBackgroundColor);
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 1rem;
  }

  .header {
    margin-bottom: 24px;
  }

  .header > h4:nth-child(1) {
    font-size: 20px;
    line-height: 30px;
  }

  .header > span:nth-child(2) > span:nth-child(1) {
    display: none;
  }

  .header > span:nth-child(2) > svg {
    width: 20px;
    height: 20px;
  }

  .question {
    padding: 1rem;
    margin-bottom: 16px;
  }

  .question > h4 {
    font-size: 14px;
    line-height: 23px;
  }

  .question > span:nth-child(3) {
    font-size: 10px;
    line-height: 18px;
  }

  .optionSection {
    padding: 16px 0;
  }

  .anseer {
    padding: 16px;
    background: var(--bodyBackgroundColor);
  }
}

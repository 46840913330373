@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.container {
  animation: appear 0.3s ease-in-out;
}

.header > h4 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 25px;
  font-weight: 600;
  line-height: 37px;
  text-align: left;
}

.header > p {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  margin-top: 0.5rem;
}

.profilePhoto {
  margin-top: 40px;
}

.profilePhoto > p {
  color: var(--grey);
  font-family: Archivo;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  margin-bottom: 1rem;
}

.profilePhoto > div {
  display: flex;
  align-items: center;
  gap: 24px;
}

.profilePhoto > div > img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
}

.profilePhoto > div > div {
  display: flex;
  align-items: center;
  gap: 24px;
}

.buttonUpper > input {
  display: none;
}

.buttonUpper > label {
  padding: 5px 20px;
  border-radius: 5px;
  background: var(--foundation-primary-p-700, #664efe);
  color: var(--white-body-text, #fff);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.profilePhoto > div > div > button {
  width: 85px;
  height: 31px;
  object-fit: cover;
  font-size: 13px;
  line-height: 140px;
}

.container > form > div {
  margin-top: 1.5rem;
}

.buttonSection {
  display: flex;
  align-items: center;
  gap: 40px;
  padding-top: 4rem;
}

.buttonSection > button:nth-child(1) {
  flex: 1.5;
}

.buttonSection > button:nth-child(2) {
  flex: 1;
}

.buttonSection > button > span {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media screen and (min-width: 737px) and (max-width: 1023px) {
  .header > h4 {
    font-size: 25px;
    line-height: 35px;
  }

  .header > p {
    font-size: 16px;
    line-height: 25px;
    margin-top: 0.5rem;
  }

  .profilePhoto {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 24px;
    background: var(--foundation-primary-p-10, #fbfaff);
  }

  .profilePhoto > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .buttonUpper {
    justify-content: center;
  }

  .profilePhoto > div > .buttonUpper {
    flex-basis: 100%;
  }
}

@media screen and (max-width: 737px) {
  .header > h4 {
    font-size: 25px;
    line-height: 35px;
  }

  .header > p {
    font-size: 16px;
    line-height: 25px;
    margin-top: 0.5rem;
  }

  .profilePhoto {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 24px;
    background: var(--foundation-primary-p-10, #fbfaff);
  }

  .profilePhoto > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .buttonUpper {
    justify-content: center;
  }

  .profilePhoto > div > .buttonUpper {
    flex-basis: 100%;
  }
}

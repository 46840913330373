.container {
  padding: 1.5rem;
}

.container > h4 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
  margin-bottom: 1.5rem;
}

.container > p {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 1.5rem;
}

.container > ul {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.container > ul > li {
  margin-bottom: 32px;
}

.container > p:last-child {
  font-weight: 500;
}

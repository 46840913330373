.container {
  padding: 80px 48px;
  background: var(--white);
  margin: 0px 24px;
}

.header {
  margin-bottom: 80px;
}

.header > p {
  color: var(--grey3);
  text-align: center;
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  width: 50%;
  margin: auto;
  margin-top: 40px;
}

.paymentDetailContainer {
  display: flex;
  align-items: center;
  gap: 40px;
}

.paymentCard {
  flex-basis: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 10px;
  margin-top: 40px;
}

.paymentCard > div:nth-child(1) {
  height: 30%;
  padding: 31px;
  width: 100%;
  border-radius: 10px 10px 0 0;
  background-color: var(--purple);
}

.paymentCard > div:nth-child(1) > p:nth-child(1) {
  color: var(--white);
  font-family: Archivo;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.paymentCard > div:nth-child(1) > p:nth-child(2) {
  color: var(--white);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.paymentCard > div:nth-child(2) {
  height: 70%;
  padding: 31px;
  width: 100%;
  border-radius: 0 0 10px 10px;
  background-color: var(--veryVeryLightPurple);
}

.paymentCard > div:nth-child(2) > p:nth-child(1) {
  color: var(--grey);

  font-family: Archivo;
  font-size: 49px;
  font-style: normal;
  font-weight: 500;
  line-height: 61px;
}

.paymentCard > div:nth-child(2) > p:nth-child(1) > span {
  font-size: 18px;
  line-height: 28px;
}

.paymentCard > div:nth-child(2) > p:nth-child(2) {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 1rem;
    margin: 0;
  }

  .header > p {
    width: 70%;
    margin-top: 8px;
  }

  .paymentDetailContainer {
    gap: 24px;
    flex-wrap: wrap;
  }

  .paymentCard {
    flex-basis: 100%;
    margin-top: 24px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 1rem;
    margin: 0;
  }

  .header > p {
    width: 100%;
    margin-top: 8px;
  }

  .paymentDetailContainer {
    gap: 24px;
    flex-wrap: wrap;
  }

  .paymentCard {
    flex-basis: 100%;
    margin-top: 24px;
  }
}

.container {
  padding: 1.5rem 1rem;
  border-radius: 10px;
  background: var(--purple);
}

.container > h4 {
  color: var(--white);
  font-family: Archivo;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 0.5rem;
}

.container > p:nth-child(2) {
  color: var(--sectionBorder);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.container > p:nth-child(3),
.container > p:nth-child(8) {
  margin-top: 24px;
  color: var(--white);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.info {
  margin-top: 0.5rem;
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid var(--sectionBorder);
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--white);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.info > span:nth-child(2),
.socialShare > div > span {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--white);
  border-radius: 50%;
  cursor: pointer;
}

.or {
  margin: 1.5rem 0;
  display: flex;
  align-items: center;
  gap: 1rem;
  color: var(--sectionBorder);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.or > hr {
  flex: 1;
}

.socialShare {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  color: var(--white);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.socialShare > div {
  display: flex;
  align-items: center;
  gap: 24px;
}

.modalContainer {
  padding: 40px 2rem;
}

.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--grey);
  font-family: Archivo;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 0.5rem;
}

.modalHeader > svg {
  cursor: pointer;
}

.modalContainer > p {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  width: 70%;
  margin-bottom: 40px;
}

.section > h5 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 0.5rem;
}

.section > p {
  color: var(--grey3);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.emailInput {
  position: relative;
  height: 52px;
  border: var(--border);
  border-radius: 5px;
  border: 1px solid var(--purple);
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}

.emailInput > input {
  width: 100%;
  height: 100%;
  color: var(--grey);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 8px;
  border: none;
  outline: none;
  border-radius: 5px;
}

.emailInput > input::placeholder {
  color: var(--placeholderGrey);
}

.emailInput > button {
  position: absolute;
  right: 8px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 72px;
  height: 36px;
}

.modalSocialShare {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 24px;
}

.modalSocialShare > div {
  flex: 1;
  border-radius: 5px;
  border: 1px solid var(--purple);
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
}

.modalSocialShare > div > p {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.modalSocialShare > div > span {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--purple);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  cursor: pointer;
}

.modalSocialShare > span {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.modalButtonSection {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
  margin-bottom: 24px;
}

.modalButtonSection > button {
  max-width: 164px;
}

.policy {
  text-align: center;
  color: var(--grey3);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.policy > a {
  color: var(--grey);
  text-decoration: none;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container > h4 {
    font-size: 14px;
    line-height: 23px;
  }

  .container > p:nth-child(2) {
    font-size: 10px;
    line-height: 18px;
  }

  .container > p:nth-child(3),
  .container > p:nth-child(8) {
    font-size: 10px;
    line-height: 18px;
  }

  .info {
    font-size: 10px;
    line-height: 18px;
  }

  .or {
    margin: 1rem 0;
  }

  .or > hr {
    flex: 1;
  }

  .socialShare {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 0.5rem;
    font-size: 10px;
    line-height: 18px;
  }
}

@media screen and (max-width: 767px) {
  .container > h4 {
    font-size: 14px;
    line-height: 23px;
  }

  .container > p:nth-child(2) {
    font-size: 10px;
    line-height: 18px;
  }

  .container > p:nth-child(3),
  .container > p:nth-child(8) {
    font-size: 10px;
    line-height: 18px;
  }

  .info {
    font-size: 10px;
    line-height: 18px;
  }

  .or {
    margin: 1rem 0;
  }

  .or > hr {
    flex: 1;
  }

  .socialShare {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 0.5rem;
    font-size: 10px;
    line-height: 18px;
  }
}

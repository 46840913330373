.container {
  padding: 80px;
  background: var(--white);
}

.levelIndicator {
  display: inline-flex;
  padding: 8px 16px;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
  background: var(--greyWhite);
}

.levelIndicator > span {
  font-family: Archivo;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;
}

.levelIndicator > svg {
  width: 24px;
  height: 24px;
}

.headertext {
  color: var(--foundation-grey-g-900, #2e2e2e);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-top: 1rem;
}

.buttonSection {
  width: 195px;
  margin: 40px 0;
}

.headertext2 {
  color: var(--foundation-grey-g-500, #737373);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.rightSection > img {
  height: 400px;
  flex-shrink: 0;
}

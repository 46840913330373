.container {
  border-radius: 10px;
  background: var(--white);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  padding: 35px 27px;
}

.header {
  padding-bottom: 24px;
  border-bottom: 1px solid var(--sectionBorder);
  color: var(--grey);
  font-family: Archivo;
  font-size: 31px;
  font-style: normal;
  font-weight: 500;
  line-height: 41px;
  margin-bottom: 0.5rem;
}

.taskDescription {
  margin-top: 24px;
}

.taskDescription,
.submission {
  margin-bottom: 24px;
}

.taskDescription > h4,
.submission > h4 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  margin-bottom: 1rem;
}

.taskDescription > ol,
.submission > ul {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-left: -20px;
}

.taskDescription > ol > li {
  margin-bottom: 1rem;
}

.gradingCriteria,
.note {
  margin-bottom: 1.5rem;
}

.gradingCriteria > h4,
.note > h4 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
}

.gradingCriteria > p,
.note > p {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.fileDownload {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--purple);
  font-family: Archivo;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  cursor: pointer;
}

.submitAssignment {
  padding: 40px 32px;
  border-radius: 10px;
  background: var(--bodyBackgroundColor);
  margin-top: 24px;
}

.submitAssignment > h4 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 1.5rem;
}

.submitAssignment > p {
  color: var(--grey);
  font-family: Archivo;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}

.dropContainer {
  border-radius: 5px;
  border: 1px dashed var(--borderPurple);
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-direction: column;
  color: var(--purple);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-top: 0.5rem;
}

.dropContainer > span:nth-child(3) {
  cursor: pointer;
  font-weight: 500;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 35px 16px;
  }

  .header {
    padding-bottom: 16px;
    font-size: 20px;
    line-height: 30px;
  }

  .taskDescription {
    margin-top: 16px;
  }

  .taskDescription,
  .submission {
    margin-bottom: 16px;
  }

  .taskDescription > h4,
  .submission > h4 {
    font-size: 12px;
    line-height: 23px;
  }

  .taskDescription > ol,
  .submission > ul {
    font-size: 12px;
    line-height: 23px;
  }

  .taskDescription > ol > li {
    margin-bottom: 0.5rem;
  }

  .gradingCriteria,
  .note {
    margin-bottom: 1rem;
  }

  .gradingCriteria > h4,
  .note > h4 {
    font-size: 12px;
    line-height: 23px;
  }

  .gradingCriteria > p,
  .note > p {
    font-size: 12px;
    line-height: 23px;
  }

  .fileDownload {
    font-size: 16px;
    line-height: 26px;
  }

  .submitAssignment {
    padding: 24px 16px;
  }

  .submitAssignment > h4 {
    font-size: 14px;
    line-height: 23px;
    margin-bottom: 1rem;
  }

  .submitAssignment > p {
    font-size: 12px;
    line-height: 23px;
  }

  .dropContainer {
    border: 1px dashed var(--borderPurple);
    gap: 0.5rem;
    font-size: 12px;
    line-height: 23px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 35px 16px;
  }

  .header {
    padding-bottom: 16px;
    font-size: 20px;
    line-height: 30px;
  }

  .taskDescription {
    margin-top: 16px;
  }

  .taskDescription,
  .submission {
    margin-bottom: 16px;
  }

  .taskDescription > h4,
  .submission > h4 {
    font-size: 12px;
    line-height: 23px;
  }

  .taskDescription > ol,
  .submission > ul {
    font-size: 12px;
    line-height: 23px;
  }

  .taskDescription > ol > li {
    margin-bottom: 0.5rem;
  }

  .gradingCriteria,
  .note {
    margin-bottom: 1rem;
  }

  .gradingCriteria > h4,
  .note > h4 {
    font-size: 12px;
    line-height: 23px;
  }

  .gradingCriteria > p,
  .note > p {
    font-size: 12px;
    line-height: 23px;
  }

  .fileDownload {
    font-size: 16px;
    line-height: 26px;
  }

  .submitAssignment {
    padding: 24px 16px;
  }

  .submitAssignment > h4 {
    font-size: 14px;
    line-height: 23px;
    margin-bottom: 1rem;
  }

  .submitAssignment > p {
    font-size: 12px;
    line-height: 23px;
  }

  .dropContainer {
    border: 1px dashed var(--borderPurple);
    gap: 0.5rem;
    font-size: 12px;
    line-height: 23px;
  }
}

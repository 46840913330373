.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header > div:nth-child(1) > h4 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;
}

.header > div:nth-child(1) > p {
  color: var(--grey3);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.header > div:nth-child(2) {
  display: flex;
  align-items: center;
  gap: 24px;
}

.header > div:nth-child(2) > p:nth-child(1) {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.header > div:nth-child(2) > span:nth-child(2) {
  width: 156px;
  display: block;
  height: 60px;
}

.paymentOptionContainer {
  margin: 40px 0;
}

.paymentOptionOuter {
  margin-bottom: 0.5rem;
}

.paymentOption {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 1rem;
  border: 1px solid var(--grey3);
  background: #fff;
  transition: var(--threeSecTransition);
}

.cardTypes {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 7px;
}

.cardTypes > div {
  padding: 5px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 35px;
  border-radius: 5px;
  border: 1px solid var(--borderGrey);
  background: #fff;
}

.cardTypes > div > img {
  height: 100%;
  width: 100%;
}

.activeNoticeOuter {
  transition: var(--threeSecTransition);
  box-sizing: border-box;
  overflow: hidden;
}

.activeNotice {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 1rem;
  border: 1px solid var(--borderGrey);
  border-radius: 0 0 10px 10px;
  border: 1px solid var(--grey3);
  background: #fff;
}

.activeNotice > img {
  width: 120px;
  height: 120px;
  flex-shrink: 0;
}

.activeNotice > p {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  width: 70%;
}

.couponContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.couponContainer > div:nth-child(1) {
  flex: 1;
}

.couponContainer > div:nth-child(2) {
  width: 107px;
}

.totalContainer {
  margin-top: 24px;
}

.totalContainer > div:nth-child(1),
.totalContainer > .totalData:last-child {
  padding: 1rem 0;
  border-top: 1px solid var(--sectionBorder);
}

.totalContainer > .totalData:last-child {
  border-bottom: 1px solid var(--sectionBorder);
}

.totalData {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.totalData > span:nth-child(1) {
  color: var(--grey3);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.totalData > span:nth-child(2) {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.totalData > span:nth-child(2) > span {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
}
.totalContainer > .totalData:last-child > span:nth-child(2) {
  color: var(--grey);
  font-family: Archivo;
  font-size: 31px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
}

.totalContainer > .totalData:last-child > span:nth-child(2) > span {
  font-family: Archivo;
  font-size: 31px;
  font-style: normal;
  font-weight: 600;
  line-height: 42px;
}

.buttonSection {
  width: 173px;
  margin-left: auto;
  margin-top: 40px;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header > div:nth-child(1) > h4 {
    font-size: 16px;
    line-height: 25px;
  }

  .header > div:nth-child(1) > p {
    font-size: 14px;
    line-height: 23px;
  }

  .header > div:nth-child(2) {
    display: none;
  }

  .paymentOptionContainer {
    margin: 32px 0;
  }

  .paymentOptionOuter {
    margin-bottom: 0.5rem;
  }

  .cardTypes {
    flex-direction: column;
  }

  .activeNotice {
    gap: 10px;
  }

  .activeNotice > img {
    width: 86px;
    height: 86px;
  }

  .activeNotice > p {
    font-size: 16px;
    line-height: 25px;
    flex: 1;
  }

  .totalContainer {
    margin-top: 32px;
  }

  .totalContainer > div:nth-child(1),
  .totalContainer > .totalData:last-child {
    padding: 10px 0;
  }

  .totalData {
    margin-bottom: 7px;
  }

  .totalData > span:nth-child(1),
  .totalData > span:nth-child(2) {
    font-size: 14px;
    line-height: 23px;
  }

  .totalData > span:nth-child(2) > span {
    font-size: 16px;
    line-height: 27px;
  }

  .totalContainer > .totalData:last-child > span:nth-child(2) {
    font-size: 25px;
    line-height: 35px;
  }

  .totalContainer > .totalData:last-child > span:nth-child(2) > span {
    font-size: 25px;
    line-height: 35px;
  }

  .buttonSection {
    width: 100%;
    margin-left: none;
  }
}

@media screen and (max-width: 767px) {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header > div:nth-child(1) > h4 {
    font-size: 16px;
    line-height: 25px;
  }

  .header > div:nth-child(1) > p {
    font-size: 14px;
    line-height: 23px;
  }

  .header > div:nth-child(2) {
    display: none;
  }

  .paymentOptionContainer {
    margin: 32px 0;
  }

  .paymentOptionOuter {
    margin-bottom: 0.5rem;
  }

  .cardTypes {
    flex-direction: column;
  }

  .activeNotice {
    gap: 10px;
  }

  .activeNotice > img {
    width: 86px;
    height: 86px;
  }

  .activeNotice > p {
    font-size: 16px;
    line-height: 25px;
    flex: 1;
  }

  .totalContainer {
    margin-top: 32px;
  }

  .totalContainer > div:nth-child(1),
  .totalContainer > .totalData:last-child {
    padding: 10px 0;
  }

  .totalData {
    margin-bottom: 7px;
  }

  .totalData > span:nth-child(1),
  .totalData > span:nth-child(2) {
    font-size: 14px;
    line-height: 23px;
  }

  .totalData > span:nth-child(2) > span {
    font-size: 16px;
    line-height: 27px;
  }

  .totalContainer > .totalData:last-child > span:nth-child(2) {
    font-size: 25px;
    line-height: 35px;
  }

  .totalContainer > .totalData:last-child > span:nth-child(2) > span {
    font-size: 25px;
    line-height: 35px;
  }

  .buttonSection {
    width: 100%;
    margin-left: none;
  }
}

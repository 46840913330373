.container {
  padding: 1rem;
  border-radius: 10px;
  border: 1px solid var(--borderGreen);
  background: var(--scheduleRightCtaGreen);
  margin-top: 20px;
}

.container > h4 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

.container > p {
  color: var(--grey);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 10px;
}

.container > button {
  width: 154px;
  margin-top: 24px;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.container {
  width: 100%;
  min-width: 346px;
  min-height: 571px;
  height: 639px;
  display: flex;
  align-items: flex-start;
  gap: 12px;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid var(--foundation-primary-p-400, #cac2fe);
  background: var(--white);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  justify-content: space-between;
}

.container > img {
  width: 100%;
  height: 200px;
  border-radius: 10px 10px 0 0;
  flex-shrink: 0;
  object-fit: cover;
}

.textSection {
  flex: 1;
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 1rem;
  flex-direction: column;
  padding: 1rem;
}

.bookmarkSection {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  user-select: none;
}

.bookmarkSection > span:nth-child(1) {
  padding: 6px 16px;
  border-radius: 50px;
  background: var(--foundation-secondary-s-600, #38ccb3);
  color: var(--foundation-grey-g-900, #2e2e2e);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.bookmarkSection > span:nth-child(2) {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--foundation-grey-g-900, #2e2e2e);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.bookmarkSection > span:nth-child(3) {
  border: 1px solid var(--purple);
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.textSection > h6 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: var(--grey);
  font-family: Archivo;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.textSection > p:nth-child(3) {
  color: var(--grey);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.textSection > p:nth-child(3) {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

.ratingSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid var(--greyWhite);
  border-bottom: 1px solid var(--greyWhite);
  padding: 1rem 0;
}

.ratingSection > div {
  display: flex;
  align-items: center;
}

.ratingSection > div:nth-child(1) {
  color: var(--grey);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  gap: 5px;
}

.ratingSection > div:nth-child(2) {
  gap: 7px;
  color: var(--grey);
  font-family: Archivo;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}

.buttonSection {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 1rem;
}

.buttonSection > button {
  width: 122px;
  padding: 5px 20px;
  font-size: 13px;
}

.price {
  color: var(--grey);
  font-family: Archivo;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 37px;
  text-align: right;
  width: 100%;
}

.bottom {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 1.5rem;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    min-height: 480px;
  }

  .container > img {
    height: 150px;
  }

  .textSection > h6 {
    font-size: 18px;
    line-height: 27px;
  }

  .textSection > p {
    font-size: 12px;
    line-height: 23px;
  }

  .textSection {
    padding: 1rem 0.5rem;
    gap: 0.5rem;
  }

  .buttonSection {
    width: 100%;
  }

  .price {
    font-size: 18px;
    line-height: 27px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    min-height: 480px;
    height: 600px;
  }

  .container > img {
    height: 150px;
  }

  .textSection > h6 {
    font-size: 18px;
    line-height: 27px;
  }

  .textSection > p {
    font-size: 12px;
    line-height: 23px;
  }

  .textSection {
    padding: 1rem 0.5rem;
    gap: 0.5rem;
  }

  .buttonSection {
    width: 100%;
  }

  .price {
    font-size: 18px;
    line-height: 27px;
  }
}

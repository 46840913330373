.container {
  padding: 35px 27px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--sectionBorder);
}

.header > div > h2 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 31px;
  font-style: normal;
  font-weight: 500;
  line-height: 41px;
  margin-bottom: 0.5rem;
}

.header > div > span {
  display: flex;
  align-items: center;
  gap: 12px;
  color: var(--timerGreen);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.header > div:nth-child(2) {
  padding: 10px;
  border-radius: 4px;
  border: 2px solid var(--black);
  background: #fff;
}

.contentReadSection {
  padding: 24px 0;
}

.contentParagraph > h4 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-top: 1.5rem;
}

.contentParagraph > p {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.imageContainer {
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.imageContainer > div {
  flex: 1;
}

.imageContainer > div > img {
  width: 100%;
}

.imageContainer > div > span {
  color: var(--grey);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.completedContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.completed {
  border-radius: 4px;
  background: #fff;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  user-select: none;
  padding: 10px;
  border: 2px solid var(--black);
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 35px 20px;
  }

  .header {
    padding-bottom: 16px;
  }

  .header > div > h2 {
    font-size: 20px;
    line-height: 30px;
  }

  .header > div > span {
    font-size: 12px;
    line-height: 23px;
  }

  .contentReadSection {
    padding: 16px 0;
  }

  .contentParagraph > h4 {
    font-size: 16px;
    line-height: 25px;
  }

  .contentParagraph > p {
    font-size: 12px;
    line-height: 23px;
  }

  .imageContainer {
    margin-top: 24px;
    flex-direction: column;
  }

  .imageContainer > div > span {
    font-size: 10px;
    line-height: 18px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 35px 20px;
  }

  .header {
    padding-bottom: 16px;
  }

  .header > div > h2 {
    font-size: 20px;
    line-height: 30px;
  }

  .header > div > span {
    font-size: 12px;
    line-height: 23px;
  }

  .contentReadSection {
    padding: 16px 0;
  }

  .contentParagraph > h4 {
    font-size: 16px;
    line-height: 25px;
  }

  .contentParagraph > p {
    font-size: 12px;
    line-height: 23px;
  }

  .imageContainer {
    margin-top: 24px;
    flex-direction: column;
  }

  .imageContainer > div > span {
    font-size: 10px;
    line-height: 18px;
  }
}

.header {
  color: var(--grey);
  font-family: Archivo;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 40px;
  text-align: center;
}

.questionSection {
  margin-bottom: 40px;
  width: 400px;
  margin: 0 auto 40px auto;
}

.questionSection > p:nth-child(1) {
  color: var(--grey);
  font-family: Archivo;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}

.options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 200px;
  margin-top: 1rem;
}

.optionsDropdown {
  margin-top: 1rem;
  width: 100%;
}

.options > div {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #000;
  font-family: Archivo;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}

.buttonSection {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  width: 100%;
  margin-top: 70px;
  flex-wrap: wrap;
}

.buttonSection > button:nth-child(1),
.buttonSection > button:nth-child(2) {
  flex-basis: 45%;
}

.buttonSection > button:nth-child(3) {
  flex-basis: 100%;
}

.buttonInner {
  display: flex;
  align-items: center;
  gap: 10px;
}

.noQuestions {
  color: #000;
  font-family: Archivo;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .header {
    font-size: 18px;
    line-height: 140%;
    margin-bottom: 32px;
  }

  .questionSection {
    margin-bottom: 30px;
    width: 90vw;
  }

  .questionSection > p:nth-child(1) {
    font-size: 18px;
    line-height: 140%;
  }
}

@media screen and (max-width: 767px) {
  .header {
    font-size: 18px;
    line-height: 140%;
    margin-bottom: 32px;
  }

  .questionSection {
    margin-bottom: 30px;
    width: 90vw;
  }

  .questionSection > p:nth-child(1) {
    font-size: 18px;
    line-height: 140%;
  }

  .buttonSection {
    gap: 16px;
  }

  .buttonSection > button:nth-child(1),
  .buttonSection > button:nth-child(2) {
    flex-basis: 100%;
  }
}

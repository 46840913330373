.container {
  padding: 40px 32px;
  border-radius: 10px;
  background: var(--white);
}

.container > h4 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.table {
  margin-top: 1rem;
}

.tableBody {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  border-bottom: 1px solid var(--sectionBorder);
}

.tableBody:first-child {
  border-top: 1px solid var(--sectionBorder);
}

.tableBody > span:not(span:nth-child(2)) {
  flex: 1;
}

.tableBody > span:nth-child(2) {
  flex: 2;
  color: var(--grey3);
}

.tableBody > span:nth-child(3) > button {
  font-size: 13px;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 16px;
  }

  .container > h4 {
    font-size: 16px;
    line-height: 25px;
  }

  .table {
    margin-top: 1rem;
  }

  .tableBody {
    justify-content: space-between;
    font-size: 14px;
    line-height: 23px;
  }

  .tableBody > span:nth-child(2) {
    flex: 1;
  }

  .tableBody > span:nth-child(1) {
    display: none;
  }

  .tableBody > span:nth-child(3) > button {
    font-size: 10px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 16px;
  }

  .container > h4 {
    font-size: 16px;
    line-height: 25px;
  }

  .table {
    margin-top: 1rem;
  }

  .tableBody {
    justify-content: space-between;
    font-size: 14px;
    line-height: 23px;
  }

  .tableBody > span:nth-child(2) {
    flex: 1;
  }

  .tableBody > span:nth-child(1) {
    display: none;
  }

  .tableBody > span:nth-child(3) > button {
    font-size: 10px;
  }
}

.container {
  padding: 40px 2rem;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 1rem;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 1rem;
  }
}

.container {
  padding: 80px 48px;
  background: var(--white);
  margin: 40px 24px 0 24px;
}

.levelIndicator {
  display: inline-flex;
  padding: 8px 16px;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
  background: var(--lightGreen);
}

.levelIndicator > span {
  font-family: Archivo;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;
}

.levelIndicator > svg {
  width: 24px;
  height: 24px;
}

.bodyMain {
  display: flex;
  align-items: flex-start;
  gap: 64px;
}

.bodyMain > div {
  flex-basis: 50%;
}

.leftSection > h4 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 61px;
  font-style: normal;
  font-weight: 500;
  line-height: 75px;
  margin: 1rem 0;
}

.leftSection > button {
  width: 145px;
  margin-top: 40px;
}

.highlights {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 10px;
}

.highlights > span:nth-child(1) {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border: 1px solid var(--veryLightPurple);
  border-radius: 50%;
}

.highlights > span:nth-child(1) > svg {
  width: 24px;
  height: 24px;
}

.rightSection > img {
  width: 100%;
  height: 400px;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 1rem;
    margin: 0;
  }

  .levelIndicator > svg {
    width: 16px;
    height: 16px;
  }

  .levelIndicator > span {
    font-size: 10px;
    line-height: 18px;
  }

  .bodyMain {
    gap: 24px;
    flex-direction: column;
  }

  .bodyMain > div {
    flex-basis: 100%;
    width: 100%;
  }

  .leftSection > h4 {
    font-size: 36px;
    line-height: 45px;
  }

  .leftSection > button {
    width: 145px;
    margin-top: 40px;
  }

  .highlights {
    margin-bottom: 8px;
  }

  .highlights > span:nth-child(1) {
    font-size: 14px;
    line-height: 23px;
  }

  .highlights > span:nth-child(1) > svg {
    width: 16px;
    height: 16px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 1rem;
    margin: 0;
  }

  .levelIndicator > svg {
    width: 16px;
    height: 16px;
  }

  .levelIndicator > span {
    font-size: 10px;
    line-height: 18px;
  }

  .bodyMain {
    gap: 24px;
    flex-direction: column;
  }

  .bodyMain > div {
    flex-basis: 100%;
    width: 100%;
  }

  .leftSection > h4 {
    font-size: 36px;
    line-height: 45px;
  }

  .leftSection > button {
    width: 145px;
    margin-top: 40px;
  }

  .highlights {
    margin-bottom: 18px;
  }

  .highlights > span:nth-child(1) {
    font-size: 14px;
    line-height: 23px;
  }

  .highlights > span:nth-child(1) > svg {
    width: 16px;
    height: 16px;
  }
}

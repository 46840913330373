.container {
  padding: 22px 10px;
  background: var(--purple);
  height: 152px;
}

.container > h4 {
  color: var(--white);
  font-family: Archivo;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;
  margin-bottom: 24px;
}

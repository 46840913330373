.container {
  height: calc(100% - 64px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 24px;
  padding: 64px;
  border-radius: 10px;
  border: 1px solid var(--greyWhite);
  background: var(--white);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
}

.container > h4 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  margin-top: 2rem;
  text-align: center;
}

.container > p {
  color: var(--grey);
  text-align: center;
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin: 1rem 0;
}

.container > button {
  width: 227px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    height: 90vh;
    margin: 16px;
    padding: 16px;
  }

  .container > h4 {
    font-size: 18px;
    line-height: 30px;
    margin-top: 1rem;
  }

  .container > p {
    font-size: 14px;
    line-height: 140%;
  }

  .container > button {
    width: 227px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  }
}

@media screen and (max-width: 767px) {
  .container {
    height: 90vh;
    margin: 16px;
    padding: 16px;
  }

  .container > h4 {
    font-size: 18px;
    line-height: 30px;
    margin-top: 1rem;
  }

  .container > p {
    font-size: 14px;
    line-height: 140%;
  }

  .container > button {
    width: 227px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  }
}

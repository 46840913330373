.container {
  padding: 80px 48px;
  background: var(--white);
  margin: 0px 24px;
}

.innerContainer {
  padding: 40px;
  background: var(--veryVeryLightPurple);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
}

.header {
  width: 80%;
}

.buttonSection {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  width: 70%;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 1rem;
    margin: 0;
  }

  .innerContainer {
    padding: 1rem;
    gap: 24px;
  }

  .buttonSection {
    gap: 24px;
    width: 70%;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 1rem;
    margin: 0;
  }

  .innerContainer {
    padding: 1rem;
    gap: 24px;
  }

  .buttonSection {
    gap: 24px;
    width: 100%;
    flex-direction: column;
  }
}

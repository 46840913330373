.container {
  padding: 13px 1rem;
  background: var(--purple);
}

.container > svg {
  cursor: pointer;
}

.sideNav {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  background-color: var(--white);
  overflow: hidden;
  width: 0;
  transition: 0.3s;
}

.sideNavHeader {
  display: flex;
  align-items: center;
  gap: 3px;
  color: var(--white);
  font-family: Archivo;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  padding: 1rem;
  background: var(--purple);
}

.sideNavHeader > svg:nth-child(3) {
  margin-left: auto;
  cursor: pointer;
}

.sideNavHeader > svg:nth-child(1) {
  cursor: pointer;
}

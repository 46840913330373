.container {
  padding: 35px 27px;
  border-radius: 10px;
  background: var(--purple);
}

.container > h4 {
  color: var(--white);
  font-family: Archivo;
  font-size: 31px;
  font-style: normal;
  font-weight: 500;
  line-height: 41px;
  margin-bottom: 12px;
}

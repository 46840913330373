.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: var(--white);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4rem;
}

.header > h4 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 31px;
  font-style: normal;
  font-weight: 600;
  line-height: 42px;
}

.header > div {
  display: flex;
  align-items: center;
  gap: 24px;
}

.header > div > p:nth-child(1) {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.header > div > span:nth-child(2) {
  width: 106px;
  display: block;
  height: 60px;
}

.bodyMain {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  margin: 20px 0;
}

.plan {
  max-width: 550px;
  min-width: 500px;
  height: 489px;
  flex-shrink: 0;
  padding: 40px;
  border-radius: 30px;
}

.plan:nth-child(2) {
  border: 1px solid var(--borderPurple);
}

.plan:nth-child(1) > .upperSection {
  border-bottom: 1px solid var(--veryVeryLightPurple);
}

.plan:nth-child(2) > .upperSection {
  border-bottom: 1px solid var(--borderPurple);
}

.plan > .upperSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
}

.plan:nth-child(1) {
  background: var(--purple);
}

.plan > .upperSection > div:nth-child(1) > p:nth-child(1) {
  font-family: Archivo;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 30px;
}

.plan:nth-child(1) > .upperSection > div:nth-child(1) > p:nth-child(1) {
  color: var(--white);
}
.plan:nth-child(2) > .upperSection > div:nth-child(1) > p:nth-child(1) {
  color: var(--grey);
}

.plan > .upperSection > div:nth-child(1) > h4:nth-child(2) {
  font-family: Archivo;
  color: var(--white);
  font-size: 61px;
  font-style: normal;
  font-weight: 400;
  line-height: 74px;
}

.plan:nth-child(1) > .upperSection > div:nth-child(1) > h4:nth-child(2) {
  color: var(--white);
}

.plan:nth-child(2) > .upperSection > div:nth-child(1) > h4:nth-child(2) {
  color: var(--grey);
}

.advantages {
  padding: 1rem 0;
}

.advantages > div {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.advantages > div > svg {
  width: 24px;
  height: 24px;
}

.plan:nth-child(1) > .advantages > div {
  color: var(--white);
}

.plan:nth-child(2) > .advantages > div {
  color: var(--grey);
}

.buttonSection {
  width: 197px;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 1rem;
    align-items: flex-start;
  }

  .header > h4 {
    font-size: 18px;
    line-height: 27px;
  }

  .header > div {
    gap: 16px;
  }

  .header > div > p:nth-child(1) {
    font-size: 14px;
    line-height: 23px;
  }

  .header > div > span:nth-child(2) {
    width: 175px;
    height: 60px;
  }

  .bodyMain {
    gap: 16px;
    flex-direction: column;
  }

  .plan {
    width: 343px;
    height: 350px;
    padding: 1rem;
  }

  .plan > .upperSection > div:nth-child(1) > p:nth-child(1) {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 8px;
  }

  .plan:nth-child(1) > .upperSection > div:nth-child(1) > p:nth-child(1) {
    color: var(--white);
  }
  .plan:nth-child(2) > .upperSection > div:nth-child(1) > p:nth-child(1) {
    color: var(--grey);
  }

  .plan > .upperSection > div:nth-child(1) > h4:nth-child(2) {
    font-size: 36px;
    line-height: 45px;
  }

  .advantages > div {
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 14px;
    line-height: 23px;
  }

  .advantages > div > svg {
    width: 16px;
    height: 16px;
  }

  .buttonSection {
    width: 177px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 1rem;
    align-items: flex-start;
    width: 100vw;
  }

  .innerContainer {
    width: 100%;
  }

  .header > h4 {
    font-size: 18px;
    line-height: 27px;
  }

  .header > div {
    gap: 16px;
  }

  .header > div > p:nth-child(1) {
    font-size: 14px;
    line-height: 23px;
    white-space: nowrap;
  }

  .header > div > span:nth-child(2) {
    width: 175px;
    height: 60px;
  }

  .bodyMain {
    gap: 16px;
    flex-direction: column;
  }

  .plan {
    width: 343px;
    height: 350px;
    padding: 1rem;
    max-width: 550px;
    min-width: auto;
  }

  .plan > .upperSection > div:nth-child(1) > p:nth-child(1) {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 8px;
  }

  .plan:nth-child(1) > .upperSection > div:nth-child(1) > p:nth-child(1) {
    color: var(--white);
  }
  .plan:nth-child(2) > .upperSection > div:nth-child(1) > p:nth-child(1) {
    color: var(--grey);
  }

  .plan > .upperSection > div:nth-child(1) > h4:nth-child(2) {
    font-size: 36px;
    line-height: 45px;
  }

  .advantages > div {
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 14px;
    line-height: 23px;
  }

  .advantages > div > svg {
    width: 16px;
    height: 16px;
  }

  .buttonSection {
    width: 177px;
  }
}

.container {
  padding: 40px 32px;
  margin: 24px 0;
  border-radius: 10px;
  background: var(--white);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  overflow-x: hidden;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header > div:nth-child(1) > h2 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
}

.header > div:nth-child(1) > p {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.header > div:nth-child(2) {
  display: flex;
  align-items: center;
  gap: 24px;
}

.header > div:nth-child(2) > span {
  width: 40px;
  height: 40px;
  border: var(--border);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid var(--purple);
  cursor: pointer;
  transition: var(--threeSecTransition);
}

.header > div:nth-child(2) > span:hover {
  background: var(--purple);
}

.header > div:nth-child(2) > span > svg {
  width: 24px;
  height: 24px;
}

.courseSection {
  display: flex;
  align-items: center;
  gap: 24px;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  margin: 24px 0;
  scroll-behavior: smooth;
}

.courseSection > div {
  width: 356px;
  flex-shrink: 0;
  flex-basis: 336px;
  box-sizing: border-box;
}

.courseSection::-webkit-scrollbar {
  display: none;
}

.courseSection {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 1rem;
  }

  .header > div:nth-child(1) > h2 {
    font-size: 18px;
    line-height: 140%;
  }

  .header > div:nth-child(1) > p {
    font-size: 12px;
    line-height: 140%;
  }

  .header > div:nth-child(2) {
    gap: 18px;
  }

  .header > div:nth-child(2) > span {
    width: 32px;
    height: 32px;
  }

  .header > div:nth-child(2) > span > svg {
    width: 18px;
    height: 18px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 1rem;
  }

  .header > div:nth-child(1) > h2 {
    font-size: 18px;
    line-height: 140%;
  }

  .header > div:nth-child(1) > p {
    font-size: 12px;
    line-height: 140%;
  }

  .header > div:nth-child(2) {
    gap: 18px;
  }

  .header > div:nth-child(2) > span {
    width: 32px;
    height: 32px;
  }

  .header > div:nth-child(2) > span > svg {
    width: 18px;
    height: 18px;
  }
}

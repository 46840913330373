.container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: var(--white);
  border-radius: 50%;
  border: 2px solid var(--purple);
}

.container:hover input ~ .checkmark {
  background-color: var(--white);
}

.container input:checked ~ .checkmark {
  background-color: var(--purple);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: var(--purple);
  border: 2px solid var(--purple);
}

.container {
  padding: 80px 48px;
  background: var(--white);
  margin: 0px 24px;
}

.header {
  width: 70%;
}

.header > button {
  margin-top: 24px;
  width: 237px;
}

.topicsSection {
  display: flex;
  align-items: flex-start;
  gap: 72px;
  margin-top: 0.5rem;
}

.explore {
  color: var(--grey);
  font-family: Archivo;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-top: 40px;
}

.leftSection {
  flex-basis: 40%;
  padding-top: 24px;
  box-sizing: border-box;
}

.active {
  color: var(--grey);
}

.inActive {
  color: var(--disabled);
}

.rightSection {
  flex-basis: 60%;
}

.leftSection {
  height: 300px;
  overflow-y: scroll;
}

.leftSection::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}

.leftSection::-webkit-scrollbar-track {
  background: #dcd7fe;
  border-radius: 10px;
}

.leftSection::-webkit-scrollbar-thumb {
  background: #664efe;
  border-radius: 10px;
}

.leftSection::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.leftSection li {
  margin-left: -10px;
  margin-bottom: 24px;
  font-family: Archivo;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  cursor: pointer;
}

.lesson {
  display: flex;
  align-items: center;
}

.rightSection > p:nth-child(1) {
  color: var(--grey);
  font-family: Archivo;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
}

.rightSection > p:nth-child(2) {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin: 1rem 0;
}

.lesson {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 10px;
}

.lesson > span:nth-child(1) {
  width: 32px;
  height: 32px;
  border: 1px solid var(--veryLightPurple);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lesson > span:nth-child(2) {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.lesson > span:nth-child(1) > svg {
  width: 24px;
  height: 24px;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 1rem;
    margin: 0;
  }

  .header {
    width: 100%;
  }

  .topicsSection {
    gap: 24px;
    flex-direction: column;
  }

  .leftSection,
  .rightSection {
    width: 100%;
  }

  .rightSection > p:nth-child(1) {
    font-size: 20px;
    line-height: 30px;
  }

  .rightSection > p:nth-child(2) {
    font-size: 14px;
    line-height: 23px;
    margin: 0.5rem 0;
  }

  .leftSection li {
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 23px;
  }

  .lesson > span:nth-child(2) {
    color: var(--grey);
    font-size: 14px;
    line-height: 23px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 1rem;
    margin: 0;
  }

  .header {
    width: 100%;
  }

  .topicsSection {
    gap: 24px;
    flex-direction: column;
  }

  .leftSection,
  .rightSection {
    width: 100%;
  }

  .rightSection > p:nth-child(1) {
    font-size: 20px;
    line-height: 30px;
  }

  .rightSection > p:nth-child(2) {
    font-size: 14px;
    line-height: 23px;
    margin: 0.5rem 0;
  }

  .leftSection li {
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 23px;
  }

  .lesson > span:nth-child(2) {
    color: var(--grey);
    font-size: 14px;
    line-height: 23px;
  }
}

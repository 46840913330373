.container {
  padding: 64px 84px 0px 40px;
  background-color: var(--veryVeryLightPurple);
  height: 100vh;
  position: relative;
}

.container > p {
  font-family: Archivo;
  font-size: 25px;
  font-weight: 400;
  line-height: 35px;
  text-align: left;
  color: var(--grey);
  margin-bottom: 1.5rem;
}

.container > div {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 72px;
}

.container > div:nth-child(2) > img {
  width: 60px;
  height: 60px;
}

.container > div > div > h4 {
  font-family: Archivo;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: var(--grey);
  margin-bottom: 0.5rem;
}

.container > div > div > p {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: var(--grey3);
}

.container > img {
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 100%;
}

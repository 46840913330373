.container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 80px;
  background: var(--white);
}

.container > img {
  width: 200px;
  height: 200px;
}

.textSection {
  text-align: center;
}

.textSection > h4 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 1rem;
}

.textSection > p {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.buttonSection {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  width: 30%;
}

.buttonInner {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media screen and (max-width: 767px) {
  .buttonSection {
    flex-wrap: wrap;
    width: 90%;
    gap: 1rem;
  }

  .buttonSection > button {
    width: 100%;
  }
}

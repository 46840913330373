.container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 80px;
  background: var(--white);
}

.header {
  text-align: center;
}

.header > h4 {
  color: var(--grey);
  text-align: center;
  font-family: Archivo;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 1rem;
}

.header > p {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.whatToExpect > p {
  color: var(--grey);
  font-family: Archivo;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
  margin-bottom: 1rem;
}

.arrayContainer {
  display: flex;
  align-items: center;
  gap: 40px;
}

.expectation {
  text-align: center;
  color: var(--grey);
  text-align: center;
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.expectation > svg {
  margin-bottom: 10px;
}

.buttonSection {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  width: 40%;
}

.buttonInner {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    gap: 40px;
    padding: 1rem;
  }

  .header {
    text-align: center;
  }

  .header > h4 {
    font-size: 18px;
    line-height: 140%;
    margin-bottom: 1rem;
  }

  .header > p {
    font-size: 14px;
    line-height: 140%;
  }

  .whatToExpect > p {
    font-size: 16px;
    line-height: 140%;
  }

  .arrayContainer {
    display: flex;
    align-items: center;
    gap: 40px;
    flex-direction: column;
  }

  .expectation {
    font-size: 14px;
    line-height: 140%;
  }

  .expectation > svg {
    margin-bottom: 10px;
  }

  .buttonSection {
    display: flex;
    gap: 30px;
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .container {
    gap: 40px;
    padding: 1rem;
  }

  .header {
    text-align: center;
  }

  .header > h4 {
    font-size: 18px;
    line-height: 140%;
    margin-bottom: 1rem;
  }

  .header > p {
    font-size: 14px;
    line-height: 140%;
  }

  .whatToExpect > p {
    font-size: 16px;
    line-height: 140%;
  }

  .arrayContainer {
    display: flex;
    align-items: center;
    gap: 40px;
    flex-direction: column;
  }

  .expectation {
    font-size: 14px;
    line-height: 140%;
  }

  .expectation > svg {
    margin-bottom: 10px;
  }

  .buttonSection {
    display: flex;
    gap: 30px;
    width: 100%;
  }
}

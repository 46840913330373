.container {
  border: 2px solid red;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
}

.container > div {
  flex-basis: 50%;
  width: 50%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
}

.decorationSection {
  background-color: var(--cream2);
}

.decorationSection > svg {
  z-index: 1;
}

.children > svg {
  position: fixed;
}

.children {
  overflow-y: auto;
}

.decorationSection > svg:nth-child(1) {
  top: 0;
  left: 0;
}

.decorationSection > svg:nth-child(2) {
  bottom: 85px;
  left: 87px;
}

.decorationSection > svg:nth-child(3) {
  bottom: 0;
  right: 30px;
}

.children > svg:nth-child(1) {
  right: 0;
  top: 0;
}

.children > svg:nth-child(2) {
  right: 41px;
  bottom: 0;
}

.body {
  position: relative;
  z-index: 2;
  margin: 30px 40px;
  width: 520px;
  min-height: calc(100% - 60px);
  box-sizing: border-box;
  padding: 40px;
  background-color: var(--white);
}

.childrenContainer {
  margin-top: 40px;
}

.stepIndicator {
  display: flex;
  align-items: center;
  justify-content: center;
}

.step:nth-child(1) > hr {
  display: none;
}

.step {
  display: flex;
  align-items: center;
  width: 100%;
}

.step:nth-child(1) {
  width: 50px;
}

.step:nth-child(2),
.step:nth-child(3) {
  flex: 1;
}

.step > hr {
  flex: 1;
  transition: all 0.3s ease-in-out;
}

.step > span {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  transition: all 0.3s ease-in-out;
}

.active > span {
  background: var(--purple);
  color: var(--white);
}

.active > hr {
  border: 1px solid var(--purple);
}

.inActive > span {
  border: 1px solid var(--disabled);
  color: var(--disabled);
}

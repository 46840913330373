.container {
  display: flex;
  align-items: center;
  gap: 63px;
  padding: 19px 0;
  color: var(--purple);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  height: 100%;
  border-top: 1px solid var(--grey);
}

.container > div {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    align-items: flex-start;
    flex-direction: column;
    gap: 17px;
    font-size: 14px;
    line-height: 23px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    align-items: flex-start;
    flex-direction: column;
    gap: 17px;
    font-size: 14px;
    line-height: 23px;
  }
}

.container {
  padding: 48px;
  border-radius: 10px;
  background: var(--white);
}

.tableHeader,
.tableBody,
.noBookmarks {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: var(--grey);

  padding: 19px 0;
}

.noBookmarks {
  justify-content: center;

  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

.tableHeader {
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  border-bottom: 1px solid var(--grey3);
}

.tableHeader > span:nth-child(1),
.tableBody > div:nth-child(1) {
  flex: 2;
}

.tableBody > div:nth-child(1) {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.tableBody > div:nth-child(1) > span:nth-child(2) > span:nth-child(2) {
  display: block;
  color: var(--grey);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.tableHeader > span:not(span:nth-child(1)),
.tableBody > span:not(div:nth-child(1)) {
  flex: 1;
}

.tableBody {
  color: var(--grey);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border-bottom: 1px solid var(--disabled);
}

.tableBody > div:nth-child(1) > span:nth-child(2) {
  cursor: pointer;
}

.tableBody > div:nth-child(1) > span:nth-child(2):hover {
  text-decoration: underline;
}

.tableBody > span:last-child {
  color: var(--deleteRed);
  cursor: pointer;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 16px;
  }

  .tableHeader,
  .tableBody {
    gap: 1.5rem;
    padding: 12px 0;
  }

  .tableHeader {
    font-size: 13px;
    line-height: 140%;
  }

  .tableHeader > span:nth-child(3),
  .tableBody > span:nth-child(3) {
    display: none;
  }

  .tableBody > div:nth-child(1) {
    gap: 1rem;
  }

  .tableBody > div:nth-child(1) > span:nth-child(2) > span:nth-child(2) {
    font-size: 13px;
    line-height: 22px;
  }

  .tableHeader > span:not(span:nth-child(1)),
  .tableBody > span:not(div:nth-child(1)) {
    flex: 1;
  }

  .tableBody > span:last-child {
    color: var(--deleteRed);
    cursor: pointer;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 16px;
  }

  .tableHeader,
  .tableBody {
    gap: 1.5rem;
    padding: 12px 0;
  }

  .tableHeader {
    font-size: 13px;
    line-height: 140%;
  }

  .tableHeader > span:nth-child(3),
  .tableBody > span:nth-child(3) {
    display: none;
  }

  .tableBody > div:nth-child(1) {
    gap: 1rem;
  }

  .tableBody > div:nth-child(1) > span:nth-child(2) > span:nth-child(2) {
    font-size: 13px;
    line-height: 22px;
  }

  .tableHeader > span:not(span:nth-child(1)),
  .tableBody > span:not(div:nth-child(1)) {
    flex: 1;
  }

  .tableBody > span:last-child {
    color: var(--deleteRed);
    cursor: pointer;
  }
}

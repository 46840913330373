.courseImageSection {
  width: 100%;
  height: 300px;
}

.courseImageSection > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.courseInformationAndProgress {
  display: flex;
  align-items: center;
  gap: 24px;
  margin: 50px 24px 0 24px;
}

.courseMain {
  margin: 24px 24px 0 24px;
}

.courseInformationAndProgress > div:nth-child(1) {
  flex-basis: 60%;
}

.courseInformationAndProgress > div:nth-child(2) {
  flex-basis: 40%;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .courseImageSection {
    height: 200px;
  }
  .courseInformationAndProgress {
    margin-top: 40px;
    flex-direction: column;
  }

  .courseInformationAndProgress > div:nth-child(1) {
    flex-basis: 100%;
  }

  .courseInformationAndProgress > div:nth-child(2) {
    flex-basis: 100%;
  }
}

@media screen and (max-width: 767px) {
  .courseImageSection {
    height: 200px;
  }
  .courseInformationAndProgress {
    margin-top: 40px;
    flex-direction: column;
  }

  .courseInformationAndProgress > div:nth-child(1) {
    flex-basis: 100%;
  }

  .courseInformationAndProgress > div:nth-child(2) {
    flex-basis: 100%;
  }
}

.container {
  height: 100%;
  overflow-y: auto;
  padding: 28px;
}

.closeSideNavv {
  text-align: right;
}

.userSection {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 21px;
}

.userSection > img {
  width: 51px;
  height: 51px;
  flex-shrink: 0;
  border-radius: 50%;
}

.userSection > div > p:nth-child(1) {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.userSection > div > p:nth-child(2) {
  color: var(--grey3);
  font-family: Archivo;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.inputSection {
  width: 100%;
  position: relative;
  margin-top: 4rem;
}

.inputSection > input {
  width: 100%;
  padding: 8px 17px 8px 57px;
  border-radius: 10px;
  border: 1px solid var(--foundation-grey-g-50, #d8d8d8);
  background: var(--white-body-text, #fff);
  color: var(--foundation-grey-g-900, #2e2e2e);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  outline: none;
}

.inputSection > svg {
  position: absolute;
  left: 17px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.navSection {
  margin-top: 32px;
}

.navSection > a,
.navSection > div {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 1.5rem;
  text-decoration: none;
  padding: 8px;
  border-radius: 4px;
  font-family: Archivo;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  transition: all 0.3s ease-in-out;
}

.active {
  background-color: var(--purple);
  color: var(--white);
}

.active > svg {
  color: var(--white);
  stroke: var(--white);
}

.inActive {
  color: var(--grey);
  stroke: var(--grey);
}

.logout {
  margin-top: 3rem;
}

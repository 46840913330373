.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header > div:nth-child(2) {
  width: 120px;
  display: none;
}

.header > div:nth-child(1) > h4 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 37px;
  margin-bottom: 10px;
}

.header > div:nth-child(1) > p {
  color: var(--grey3);
  font-family: Archivo;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}

.header > div:nth-child(2) {
  width: 200px;
}

.main {
  margin-top: 2rem;
  display: flex;
  align-items: stretch;
  border: 1px solid var(--grey);
}

.main > div:nth-child(1) {
  width: 285px;
  color: var(--grey);
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: column;
}

.week {
  font-family: Archivo;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  border-bottom: 0.5px solid var(--grey);
  border-right: 1px solid var(--black);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
  cursor: pointer;
  flex: 1;
}

.inActiveWeek {
  color: var(--grey3);
}

.activeWeek {
  color: var(--grey);
}

.main > div:nth-child(2) {
  flex: 1;
}

.activeWeekDetails {
  padding: 40px 105px;
}

.activeWeekHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.activeWeekHeader > h4 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.complete {
  padding: 5px 20px;
  border-radius: 5px;
  background: var(--successGreen);
  /* display: flex; */
  align-items: center;
  gap: 10px;
  color: var(--white);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  display: none;
}

.activeWeekBody {
  margin-top: 2rem;
}

.activeWeekTopic {
  display: flex;
  align-items: flex-start;
  gap: 14px;
  margin-bottom: 30px;
}

.activeWeekTopic > div > span:nth-child(1) {
  color: var(--grey);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  display: block;
}

.activeWeekTopic > div > span:nth-child(2) {
  color: var(--grey3);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.noCourseWeek {
  color: var(--grey3);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  padding: 1rem 0;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .header {
    margin-top: 24px;
  }

  .header > div:nth-child(2) {
    display: block;
  }

  .header > div:nth-child(1) > h4 {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 0px;
  }

  .header > div:nth-child(1) > p {
    font-size: 10px;
    line-height: 18px;
  }

  .main {
    margin-top: 1.5rem;
    border: none;
  }

  .main > div:nth-child(1) {
    display: none;
  }

  .activeWeekDetails {
    padding: 0px;
    border-left: none;
  }

  .activeWeekHeader > h4 {
    font-size: 16px;
    line-height: 25px;
  }

  .complete {
    font-size: 10px;
    line-height: 18px;
  }

  .activeWeekBody {
    margin-top: 1rem;
  }

  .activeWeekTopic {
    margin-bottom: 16px;
  }

  .activeWeekTopic > div > span:nth-child(1) {
    font-size: 14px;
    line-height: 23px;
  }

  .activeWeekTopic > div > span:nth-child(2) {
    font-size: 10px;
    line-height: 18px;
  }
}

@media screen and (max-width: 767px) {
  .header {
    margin-top: 24px;
  }

  .header > div:nth-child(2) {
    display: block;
    width: 150px;
  }

  .header > div:nth-child(1) > h4 {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 0px;
  }

  .header > div:nth-child(1) > p {
    font-size: 10px;
    line-height: 18px;
  }

  .main {
    margin-top: 1.5rem;
    border: none;
  }

  .main > div:nth-child(1) {
    display: none;
  }

  .activeWeekDetails {
    padding: 0px;
    border-left: none;
  }

  .activeWeekHeader > h4 {
    font-size: 16px;
    line-height: 25px;
  }

  .complete {
    font-size: 10px;
    line-height: 18px;
  }

  .activeWeekBody {
    margin-top: 1rem;
  }

  .activeWeekTopic {
    margin-bottom: 16px;
  }

  .activeWeekTopic > div > span:nth-child(1) {
    font-size: 14px;
    line-height: 23px;
  }

  .activeWeekTopic > div > span:nth-child(2) {
    font-size: 10px;
    line-height: 18px;
  }
}

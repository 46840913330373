.container {
  padding: 40px 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  flex-wrap: wrap;
}

.outerContainer > h4 {
  padding: 40px 1.5rem 0 1.5rem;
  color: #000;
  font-family: Archivo;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.container > div {
  flex-basis: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.noCourses {
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  color: var(--grey3);
  text-align: center;
  width: 100%;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    align-items: center;
  }

  .container > div {
    flex-basis: 48%;
  }
}

@media screen and (max-width: 767px) {
  .container {
    align-items: center;
  }

  .container > div {
    flex-basis: 100%;
  }
}

.container {
  display: flex;
  align-items: flex-start;
  gap: 80px;
  padding: 80px;
  background: var(--white);
}

.leftSection {
  width: 30%;
}

.rightSection {
  width: 55%;
}

.buttonInner {
  display: flex;
  align-items: center;
  gap: 10px;
}

.buttonSection {
  width: 215px;
  margin-left: auto;
}

.container {
  padding: 80px;
  width: 100vw;
}

.header {
  margin-bottom: 40px;
}

.header > svg {
  cursor: pointer;
}

.bodyMain {
  display: flex;
  align-items: flex-start;
  gap: 100px;
}

.switchPayment {
  width: 440px;
  height: 190px;
  border: var(--border);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  background: var(--purple);
  border-radius: 30px;
  border: 1px solid var(--borderPurple);
}

.switchPayment > div:nth-child(1) {
  display: flex;
  align-items: center;
  gap: 20px;
}

.switchPayment > div:nth-child(1) > div:nth-child(1) {
  width: 45.406px;
  height: 45.406px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--white);
  border-radius: 50%;
}

.switchPayment > div:nth-child(1) > div:nth-child(1) > img {
  width: 80%;
  height: 80%;
}

.switchPayment > div:nth-child(1) > h4 {
  color: var(--white);
  font-family: Archivo;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
}

.leftButtonSection {
  width: 210px;
}

.paymentSection {
  flex: 1;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 16px;
  }

  .bodyMain {
    flex-direction: column;

    gap: 16px;
  }

  .switchPayment {
    width: 100%;
    height: 154px;
    gap: 16px;
  }

  .paymentSection {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 16px;
  }

  .bodyMain {
    flex-direction: column;
    gap: 16px;
  }

  .switchPayment {
    width: 100%;
    height: 154px;
    gap: 16px;
  }

  .paymentSection {
    width: 100%;
  }
}

.container {
  display: flex;
  align-items: stretch;
  gap: 24px;
  padding: 24px;
}

.container > div:nth-child(2) {
  width: 336px;
}

.container > div:nth-child(1) {
  flex: 1;
}

.container > div:nth-child(1) > section {
  margin-bottom: 24px;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    flex-direction: column;
    padding: 24px 16px;
  }

  .container > div:nth-child(2) {
    order: 0;
    width: 100%;
  }

  .container > div:nth-child(1) {
    order: 1;
  }
}

@media screen and (max-width: 767px) {
  .container {
    flex-direction: column;
    padding: 24px 16px;
  }

  .container > div:nth-child(2) {
    order: 0;
    width: 100%;
  }

  .container > div:nth-child(1) {
    order: 1;
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 10px;
  background: var(--white);
  padding: 40px 32px;
}

.container > img {
  width: 300px;
  height: 200px;
}

.container > h4 {
  color: var(--grey);
  text-align: center;
  font-family: Archivo;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.container > p {
  color: var(--grey);
  text-align: center;
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.container > button {
  width: 193px;
  margin-top: 24px;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 40px 16px;
  }

  .container > img {
    width: 343px;
    height: 228.667px;
  }

  .container > h4 {
    font-size: 20px;
    line-height: 30px;
  }

  .container > p {
    font-size: 16px;
    line-height: 25px;
  }

  .container > button {
    width: 196px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 40px 16px;
  }

  .container > img {
    width: 343px;
    height: 228.667px;
  }

  .container > h4 {
    font-size: 20px;
    line-height: 30px;
  }

  .container > p {
    font-size: 16px;
    line-height: 25px;
  }

  .container > button {
    width: 196px;
  }
}

.profilePhoto {
  margin-top: 40px;
}

.profilePhoto > p {
  color: var(--grey);
  font-family: Archivo;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  margin-bottom: 1rem;
}

.profilePhoto > div {
  display: flex;
  align-items: center;
  gap: 24px;
}

.profilePhoto > div > img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
}

.profilePhoto > div > div {
  display: flex;
  align-items: center;
}

.buttonUpper > input {
  display: none;
}

.buttonUpper>label
/* .buttonLower>button */ {
  padding: 5px 20px;
  border-radius: 5px;
  border: 2px solid var(--purple);
  background: var(--white);
  color: var(--purple);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.buttonUpper > label:last-child {
  border: none;
}

.buttonLower > button {
  font-size: 1rem;
  padding: 0.7rem 2.5rem;
  width: 200px;
  float: right;
}

.buttonLower {
  gap: 24px;
  display: flex;
  padding-top: 1.5rem;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid var(--sectionBorder);
}

.profilePhoto > div > div > button {
  width: 85px;
  height: 31px;
  object-fit: cover;
  font-size: 13px;
  line-height: 140px;
}

.flex {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
}

.flex > div:nth-child(1) {
  width: 127px;
}

.flex > div:nth-child(2) {
  flex: 1;
}

.flex > div:nth-child(2) input {
  margin: 0;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .profilePhoto {
    margin-top: 40px;
    display: flex;
    padding: 24px;
    background: var(--foundation-primary-p-10, #fbfaff);
  }

  .buttonUpper {
    justify-content: center;
  }

  .profilePhoto > div > .buttonUpper {
    flex-basis: 100%;
  }

  .profilePhotoTip {
    font-size: 12px;
    line-height: 23px;
  }
}

@media screen and (max-width: 767px) {
  .profilePhoto {
    margin-top: 40px;
    display: flex;
    padding: 24px;
    background: var(--foundation-primary-p-10, #fbfaff);
  }

  .buttonUpper {
    justify-content: center;
  }

  .profilePhoto > div > .buttonUpper {
    flex-basis: 100%;
  }

  .profilePhotoTip {
    font-size: 12px;
    line-height: 23px;
  }
}

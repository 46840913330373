.container {
  height: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}

.breadCrumbContainer {
  padding: 24px;
  background: var(--white);
}

.body {
  padding: 24px;
  flex: 1;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}

.bodyFlex {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  margin-top: 24px;
  flex: 1;
  overflow-y: auto;
}

.bodyFlex > section:nth-child(1) {
  flex-basis: 376px;
}

.bodyFlex > section:nth-child(2),
.noCourseWeek {
  flex: 1;
  height: 100%;
}

.noCourseWeek {
  padding: 49px;
  border-radius: 10px;
  background: var(--white);

  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

@media screen and (max-width: 767px) and (max-width: 1023px) {
  .bodyFlex {
    margin-top: 16px;
    overflow-y: auto;
    flex-wrap: wrap;
  }

  .bodyFlex > section:nth-child(1),
  .bodyFlex > section:nth-child(2) {
    flex-basis: 100%;
    height: auto;
  }
}

.container {
  padding: 49px;
  height: inherit;
  border-radius: 10px;
  background: var(--white);
}

.weekIndicator {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.weekIndicator > span:nth-child(1) {
  padding: 3px 15px;
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  border-radius: 5px;
  background: var(--greyWhite);
}

.weekIndicator > span:nth-child(2) {
  border-radius: 5px;
  background: var(--disabled);
  padding: 5px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--white);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.container > h4 {
  margin: 24px 0;
  color: var(--grey);
  font-family: Archivo;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.video {
  display: flex;
  align-items: flex-start;
  gap: 14px;
  cursor: pointer;
  margin-bottom: 24px;
}

.video > svg {
  width: 24px;
  height: 24px;
}

.video > div > span:nth-child(1) {
  color: var(--grey);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  display: block;
}

.video > div > span:nth-child(2) {
  color: var(--grey);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

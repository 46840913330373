.header,
.tableBody {
  display: flex;
  gap: 1rem;
  padding: 14px 58px;
}

.tableBodyContainer {
  margin-top: 1.5rem;
}

.tableBody:not(:last-child) {
  border-bottom: 1px solid var(--getStartedBorder);
}

.header {
  background: var(--sideNavBackground);
}

.resourceBit:not(:last-child) {
  margin-bottom: 20px;
}

.header,
.tableBody > div:nth-child(1),
.resourceBit > h4 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}

.header > span,
.tableBody > div {
  flex: 1;
}

.resourceBit > div > a {
  display: block;
  color: var(--grey3);
  font-family: Archivo;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-decoration-line: underline;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .header,
  .tableBody {
    padding: 16px;
  }

  .header,
  .tableBody > div:nth-child(1),
  .resourceBit > h4 {
    font-size: 14px;
    line-height: 23px;
  }

  .header > span:nth-child(2),
  .tableBody > div:nth-child(2) {
    flex: 2;
  }

  .header > span:nth-child(1),
  .tableBody > div:nth-child(1) {
    flex: 1;
  }

  .resourceBit > div > a {
    font-size: 14px;
    line-height: 28px;
  }
}

@media screen and (max-width: 767px) {
  .header,
  .tableBody {
    padding: 16px;
  }

  .header,
  .tableBody > div:nth-child(1),
  .resourceBit > h4 {
    font-size: 14px;
    line-height: 23px;
  }

  .header > span:nth-child(2),
  .tableBody > div:nth-child(2) {
    flex: 2;
  }

  .header > span:nth-child(1),
  .tableBody > div:nth-child(1) {
    flex: 1;
  }

  .resourceBit > div > a {
    font-size: 14px;
    line-height: 28px;
  }
}

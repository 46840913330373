.container {
  width: 100%;
  user-select: none;
}

.listNav {
  display: flex;
  align-items: stretch;
  width: 100%;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.listNav::-webkit-scrollbar {
  display: none;
}

.div {
  text-align: center;
  padding: 1rem;
  flex-grow: 0;
  flex-shrink: 0;
  color: var(--grey);

  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px 10px 0 0;
  background: var(--sectionsNav);
}

.div > span {
  cursor: pointer;
}

.activeDiv {
  text-align: center;
  padding: 1rem;
  color: var(--grey);
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border-radius: 10px 10px 0 0;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes borderEffect {
  0% {
    border-bottom: 2px solid #f2f3f8;
  }

  100% {
    border-bottom: 2px solid rgb(28 37 78 / 1);
  }
}

.activeDiv > span {
  cursor: pointer;
}

@media (min-width: 767px) and (max-width: 1023px) {
  .container {
    position: static;
  }
}

@media (max-width: 768px) {
  .container {
    position: static;
  }

  .div {
    flex-basis: 33.3%;
    font-size: 10px;
    line-height: 18px;
    padding: 0.5rem;
  }

  .activeDiv {
    flex-basis: 33.3%;
    font-size: 10px;
    line-height: 18px;
    padding: 0.7rem;
  }
}

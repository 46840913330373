.container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-direction: column;
  padding: 80px;
  background: #fff;
}

.container > img {
  width: 200px;
  height: 200px;
}

.container > h4 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
}

.container > p {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.buttonSection {
  display: flex;
  align-items: center;
  gap: 40px;
  margin-top: 30px;
}

.buttonSection > button {
  width: 295px;
}

.container {
  padding: 40px 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  border-radius: 0 0 10px 10px;
  background: var(--white);
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.loadingContainer {
  padding: 40px 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  border-radius: 0 0 10px 10px;
  background: var(--white);
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.container::-webkit-scrollbar {
  display: none;
}

.container > div {
  flex-basis: 32%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.noCourses {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    align-items: center;
  }

  .container > div {
    flex-basis: 48%;
  }
}

@media screen and (max-width: 767px) {
  .container {
    align-items: center;
  }

  .container > div {
    flex-basis: 100%;
  }
}

.container {
  padding: 42px;
  background: url("../../Assets/Images/helloUserBg.png");
  border-radius: 10px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06),
    0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 14px;
  flex-direction: column;
  margin: 1rem 1.5rem 0rem 1.5rem;
}

.container > h4 {
  color: var(--white);
  font-family: Archivo;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
}

.inputSection {
  width: 500px;
  height: 50px;
  position: relative;
}

.inputSection > input {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: var(--white);
  color: var(--grey);
  font-family: Archivo;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  text-indent: 70px;
  border: none;
  outline: none;
}

.inputSection > input::placeholder {
  color: var(--grey);
  font-family: Archivo;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}

.inputSection > svg {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 30px;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 16px;
    gap: 27px;
    margin: 1rem 1rem 0rem 1rem;
  }

  .container > h4 {
    font-size: 18px;
    line-height: 27px;
  }

  .inputSection {
    width: 100%;
    height: 50px;
  }

  .inputSection > input {
    font-size: 14px;
    line-height: 140%;
    text-indent: 50px;
  }

  .inputSection > input::placeholder {
    font-size: 14px;
    line-height: 140%;
  }

  .inputSection > svg {
    left: 20px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 16px;
    gap: 27px;
    margin: 1rem 1rem 0rem 1rem;
  }

  .container > h4 {
    font-size: 18px;
    line-height: 27px;
  }

  .inputSection {
    width: 100%;
    height: 50px;
  }

  .inputSection > input {
    font-size: 14px;
    line-height: 140%;
    text-indent: 50px;
  }

  .inputSection > input::placeholder {
    font-size: 14px;
    line-height: 140%;
  }

  .inputSection > svg {
    left: 20px;
  }
}

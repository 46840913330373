.container {
  border-radius: 10px;
  background: var(--white);
  padding: 40px 32px;
}

.container > h4 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 0.5rem;
}

.container > p {
  color: var(--grey3);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.progressWarning {
  padding: 0.5rem 1rem;
  border-radius: 10px;
  background: var(--warningYellow);
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin: 24px 0;
}

.progressWarning > span > span {
  font-weight: 500;
}

.progressNumbers,
.progressBars {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}

.progressNumbers {
  color: var(--grey);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.progressBars {
  margin-top: 24px;
}

.progressBars > div {
  flex: 1;
}

.pioneerContainer {
  display: flex;
  align-items: flex-start;
  margin-top: 24px;
}

.pioneerContainer > h4,
.pioneerContainer > ul {
  flex: 1;
}

.pioneerContainer > h4 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
}

.pioneerContainer > ul {
  list-style-type: none;
  margin-left: -20px;
}

.pioneerContainer > ul > li {
  margin-left: -20px;
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  color: var(--grey);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.pioneerContainer > ul > li > span:nth-child(1) {
  background-color: var(--grey);
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
}

.buttonContainer > button {
  width: 167px;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 1rem;
  }

  .container > h4 {
    font-size: 18px;
    line-height: 27px;
  }

  .container > p {
    font-size: 14px;
    line-height: 23px;
  }

  .progressWarning {
    padding: 1rem;
    font-size: 14px;
    line-height: 23px;
    margin: 16px 0;
  }

  .progressNumbers {
    font-size: 10px;
    line-height: 18px;
  }

  .progressBars {
    margin-top: 16px;
  }

  .pioneerContainer {
    flex-direction: column;
    margin-top: 16px;
    gap: 1rem;
  }

  .pioneerContainer > h4 {
    font-size: 18px;
    line-height: 27px;
  }

  .pioneerContainer > ul > li {
    font-size: 10px;
    line-height: 18px;
  }

  .buttonContainer {
    justify-content: flex-start;
    margin-top: 1rem;
  }

  .buttonContainer > button {
    width: 161px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 1rem;
  }

  .container > h4 {
    font-size: 18px;
    line-height: 27px;
  }

  .container > p {
    font-size: 14px;
    line-height: 23px;
  }

  .progressWarning {
    padding: 1rem;
    font-size: 14px;
    line-height: 23px;
    margin: 16px 0;
  }

  .progressNumbers {
    font-size: 10px;
    line-height: 18px;
  }

  .progressBars {
    margin-top: 16px;
  }

  .pioneerContainer {
    flex-direction: column;
    margin-top: 16px;
    gap: 1rem;
  }

  .pioneerContainer > h4 {
    font-size: 18px;
    line-height: 27px;
  }

  .pioneerContainer > ul > li {
    font-size: 10px;
    line-height: 18px;
  }

  .buttonContainer {
    justify-content: flex-start;
    margin-top: 1rem;
  }

  .buttonContainer > button {
    width: 161px;
  }
}

.container {
  border-radius: 10px;
  background: var(--white);
  padding: 40px 32px;
}

.container > h4 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 0.5rem;
}

.container > p {
  color: var(--grey3);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.statCOntainer {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-top: 1.5rem;
}

.statCOntainer > div {
  width: 220px;
  padding: 16px;
  border-radius: 5px;
}

.statCOntainer > div:nth-child(1) {
  color: var(--grey);
  background: #ffd029;
}

.statCOntainer > div:nth-child(2) {
  color: var(--white);
  background: #63ad0e;
}

.statCOntainer > div > h4 {
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.statCOntainer > div > p {
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-top: 1rem;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 16px;
  }

  .container > h4 {
    font-size: 18px;
    line-height: 27px;
  }

  .container > p {
    font-size: 14px;
    line-height: 23px;
  }

  .statCOntainer {
    gap: 1.5rem;
    margin-top: 1rem;
  }

  .statCOntainer > div {
    width: 50%;
  }

  .statCOntainer > div > h4 {
    font-size: 18px;
    line-height: 27px;
  }

  .statCOntainer > div > p {
    font-size: 14px;
    line-height: 23px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 16px;
  }

  .container > h4 {
    font-size: 18px;
    line-height: 27px;
  }

  .container > p {
    font-size: 14px;
    line-height: 23px;
  }

  .statCOntainer {
    gap: 1.5rem;
    margin-top: 1rem;
  }

  .statCOntainer > div {
    width: 50%;
  }

  .statCOntainer > div > h4 {
    font-size: 18px;
    line-height: 27px;
  }

  .statCOntainer > div > p {
    font-size: 14px;
    line-height: 23px;
  }
}

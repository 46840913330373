.container {
  min-height: 100%;
  display: flex;
  align-items: stretch;
  gap: 24px;
  flex-wrap: wrap;
  padding: 24px;
}

.container > div:nth-child(1),
.container > div:nth-child(3) {
  flex: 64%;
}

.container > div:nth-child(2),
.container > div:nth-child(4) {
  flex: 33%;
}

.container > div:nth-child(5) {
  flex: 100%;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    gap: 16px;
    padding: 16px;
  }

  .container > div:nth-child(1),
  .container > div:nth-child(3) {
    flex: 100%;
  }

  .container > div:nth-child(2),
  .container > div:nth-child(4) {
    flex: 100%;
  }
}

@media screen and (max-width: 767px) {
  .container {
    gap: 16px;
    padding: 16px;
  }

  .container > div:nth-child(1),
  .container > div:nth-child(3) {
    flex: 100%;
  }

  .container > div:nth-child(2),
  .container > div:nth-child(4) {
    flex: 100%;
  }
}

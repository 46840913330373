.container {
  padding: 2rem;
  background-color: var(--white);
  box-shadow: 0px 1px 2px 0px #0000000d;
  border-radius: 10px;
  margin-top: 1.5rem;
}

.innerContainer {
  background: var(--veryVeryLightPurple);
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 50px;
}
.innerContainer > div {
  width: 50%;
}

.textSection > h4 {
  font-family: Archivo;
  font-size: 20px;
  font-weight: 400;
  line-height: 29px;
  text-align: left;
}

.textSection > p {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  margin-top: 0.5rem;
}

.textSection > p > span {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
}

.imageSection {
  width: 50%;
}

.imageSection > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.textSection > button {
  margin-top: 1rem;
  max-width: 180px;
}

@media screen and (max-width: 767px) {
  .container {
    padding: 1rem;
    margin-top: 1rem;
  }

  .innerContainer {
    padding: 12px;
    gap: 20px;
    align-items: flex-start;
    flex-direction: column;
  }

  .innerContainer > div {
    width: 100%;
  }
}

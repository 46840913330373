.innerContainer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.header > h4 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 39px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
  text-align: center;
  margin-bottom: 8px;
}

.header > img {
  width: 280px;
  height: 280px;
  margin: 2rem auto;
  display: block;
}

.header > p {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 40px;
  text-align: center;
}

.container > button {
  margin-bottom: 2rem;
}

.continueWith {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 2rem;
}

.continueWith > span {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
}

.continueWith > hr {
  flex: 1;
  border-width: 1px 0 0 0;
  border-top: 1px solid #737373;
}

.container > form {
  margin: 0.5rem 0;
}

.container > p {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  margin-bottom: 1rem;
  color: var(--grey);
}

.container > p > span {
  color: var(--purple);
  cursor: pointer;
}

.rememberAndForgot {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rememberAndForgot > div:nth-child(1) {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--grey);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.rememberAndForgot > div:nth-child(1) > input {
  opacity: 0.38;
}

.rememberAndForgot > a:nth-child(2) {
  color: var(--purple);
  font-family: Archivo;
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  text-decoration: none;
}

.buttonSection {
  margin: 40px 0;
  width: 100%;
}

.googleSignUp {
  display: flex;
  align-items: center;
  gap: 24px;
}

.googleSignUp > hr {
  flex: 1;
}

.googleSignUp > span {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.googleSignUpIcons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin: 24px 0;
}

.googleSignUpIcons > span {
  border: 1px solid #b9b9b9;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.alternate {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
}

.alternate > a {
  color: var(--foundation-primary-p-900, #3d20fe);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  text-decoration: none;
}

.modalContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

.modalContainer > img {
  width: 200px;
  height: 200px;
}

.modalContainer > h4 {
  color: var(--grey);
  text-align: center;
  margin-top: 0.5rem;
  font-family: Archivo;
  font-size: 39px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
}

.modalContainer > p {
  color: var(--grey);
  text-align: center;
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin: 0 auto 0.5rem auto;
  width: 45%;
}

.modalButtonSection {
  width: 50%;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 1rem;
  }

  .logo {
    margin-bottom: 32px;
    width: 35px;
    height: 35px;
  }

  .header > h4 {
    font-size: 25px;
    line-height: 35px;
    text-align: left;
  }

  .header > p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    margin: 0.5rem 0;
    text-align: left;
  }

  .continueWith {
    display: none;
  }

  .alternate,
  .alternate > a {
    font-size: 14px;
    line-height: 23px;
  }

  .modalContainer > h4 {
    font-size: 25px;
    line-height: 35px;
  }

  .modalContainer > p {
    font-size: 14px;
    line-height: 23px;
    width: 50%;
  }

  .modalButtonSection {
    width: 50%;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 1rem;
  }

  .logo {
    margin-bottom: 32px;
    width: 35px;
    height: 35px;
  }

  .header > img {
    width: 160px;
    height: 160px;
  }

  .header > h4 {
    font-size: 25px;
    line-height: 35px;
    text-align: left;
  }

  .header > p,
  .container > p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    margin: 1rem 0;
    text-align: left;
  }

  .continueWith {
    display: none;
  }

  .alternate,
  .alternate > a {
    font-size: 14px;
    line-height: 23px;
  }

  .modalContainer > h4 {
    font-size: 25px;
    line-height: 35px;
  }

  .modalContainer > p {
    font-size: 14px;
    line-height: 23px;
    width: 50%;
  }

  .modalButtonSection {
    width: 50%;
  }
}

.container {
  padding: 40px 2rem;
  border-radius: 10px;
  background: url("../../Assets/Images/collaborationHub4Bg.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.container > h4 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 0.5rem;
}

.container > ol {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-left: -20px;
}

.container > ul > li {
  margin-bottom: 0.5rem;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 1rem;
  }

  .container > h4 {
    font-size: 18px;
    line-height: 27px;
  }

  .container > ol {
    font-size: 14px;
    line-height: 23px;
  }

  .container > ul > li {
    margin-bottom: 0.5rem;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 1rem;
  }

  .container > h4 {
    font-size: 18px;
    line-height: 27px;
  }

  .container > ol {
    font-size: 14px;
    line-height: 23px;
  }

  .container > ul > li {
    margin-bottom: 0.5rem;
  }
}

.container {
  border-radius: 10px;
  background: var(--white);
  padding: 40px 32px;
}

.container > h4 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 1rem;
}

.faq:not(:last-child) {
  margin-bottom: 1rem;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 1rem;
  }

  .container > h4 {
    font-size: 18px;
    line-height: 27px;
  }

  .faq:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 1rem;
  }

  .container > h4 {
    font-size: 18px;
    line-height: 27px;
  }

  .faq:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}

.container {
  padding: 80px;
  background: var(--white);
}

.header > h4 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
}

.header > p {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.blogSuggestions {
  margin-top: 40px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.blogCard {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 1rem;
  min-height: 572px;
  width: 440px;
  border-radius: 10px;
  border: 1px solid var(--foundation-primary-p-400, #cac2fe);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}

.blogCard > img {
  width: 100%;
  height: 360px;
  object-fit: cover;
  flex-shrink: 0;
  border-radius: 10px 10px 0px 0px;
}

.blogCard > div:nth-child(2) {
  width: 100%;
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 1rem;
  box-sizing: border-box;
}

.blogCard > div:nth-child(2) > p:nth-child(1) {
  color: var(--successGreen);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.blogCard > div:nth-child(2) > h4:nth-child(2) {
  color: var(--grey);
  font-family: Archivo;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
}

.blogCard > div:nth-child(2) > p:nth-child(3) {
  margin-top: auto;
  color: var(--foundation-grey-g-500, #737373);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.container {
  padding: 40px 2rem;
}

.dontMissOut {
  padding: 40px 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: var(--border);
  gap: 1rem;
  border-radius: 10px;
  border: 1px solid var(--borderGreen);
  background: var(--lightGreen);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  margin: 1rem 0;
}

.dontMissOut > div:nth-child(1) > h4 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
}

.dontMissOut > div:nth-child(1) {
  flex: 1;
}

.dontMissOut > div:nth-child(1) > p {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.dontMissOut > button:nth-child(2) {
  width: 122px;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 1rem;
  }

  .dontMissOut {
    padding: 1rem;
    align-items: flex-start;
    flex-direction: column;
    gap: 1.5rem;
  }

  .dontMissOut > div:nth-child(1) > h4 {
    font-size: 18px;
    line-height: 140%;
  }

  .dontMissOut > div:nth-child(1) {
    flex: 1;
  }

  .dontMissOut > div:nth-child(1) > p {
    font-size: 14px;
    line-height: 140%;
  }

  .dontMissOut > button:nth-child(2) {
    width: 122px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 1rem;
  }

  .dontMissOut {
    padding: 1rem;
    align-items: flex-start;
    flex-direction: column;
    gap: 1.5rem;
  }

  .dontMissOut > div:nth-child(1) > h4 {
    font-size: 18px;
    line-height: 140%;
  }

  .dontMissOut > div:nth-child(1) {
    flex: 1;
  }

  .dontMissOut > div:nth-child(1) > p {
    font-size: 14px;
    line-height: 140%;
  }

  .dontMissOut > button:nth-child(2) {
    width: 122px;
  }
}

.container,
.modalcontainer {
  padding: 30px;
  border-radius: 10px;
  background: var(--white);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  height: 100%;
  box-sizing: border-box;
}

.container > h4 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 10px;
}

.container > p {
  color: var(--grey3);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.container > a > button,
.container > button {
  width: 176px;
}

.container > a:nth-child(3) > button {
  margin-top: 40px;
  margin-bottom: 1rem;
}

.modalcontainer > h4 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 39px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
  margin-bottom: 1rem;
}

.modalcontainer > p {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.doNotShare {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0.5rem;
  border-radius: 5px;
  background: var(--lightGreen);
  margin-top: 24px;
}

.doNotShare > p {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.modalcontainer > div:nth-child(4) {
  margin-top: 40px;
}

.buttonSection {
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: flex-end;
  margin-top: 40px;
}

.buttonSection > button {
  width: auto;
  padding: 10px 30px;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 24px;
  }

  .container > h4 {
    font-size: 18px;
    line-height: 27px;
  }

  .container > p {
    font-size: 14px;
    line-height: 23px;
  }

  .container > button {
    width: 162px;
  }

  .container > button:nth-child(3) {
    margin-top: 10px;
    margin-bottom: 0.5rem;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 24px;
  }

  .container > h4 {
    font-size: 18px;
    line-height: 27px;
  }

  .container > p {
    font-size: 14px;
    line-height: 23px;
  }

  .container > button {
    width: 162px;
  }

  .container > button:nth-child(3) {
    margin-top: 10px;
    margin-bottom: 0.5rem;
  }
}

.container {
  padding: 80px 48px;
  background: var(--white);
  margin: 0px 24px;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 1rem;
    margin: 0;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 1rem;
    margin: 0;
  }
}

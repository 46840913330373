.container {
  padding: 24px;
  background: var(--white);
  height: calc(100% - 152px);
  overflow-y: auto;
}

.lessonHeader {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 1rem;
}

.lessonHeader > span {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
}

.lessonHeader > svg {
  transition: all 0.3s ease-in-out;
}

.video {
  display: flex;
  align-items: flex-start;
  gap: 14px;
  margin-bottom: 24px;
  cursor: pointer;
  padding: 0.5rem;
}

.video > svg {
  width: 24px;
  height: 24px;
}

.video > div {
  flex: 1;
}

.video > div > span:nth-child(1) {
  color: var(--grey);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  display: block;
}

.video > div > span:nth-child(2) {
  color: var(--grey);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.lessonContent {
  overflow-y: hidden;
  transition: all 0.3s ease-in-out;
}

.noContent {
  color: var(--grey);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 0.5rem;
}

.active {
  border: 1px solid var(--veryLightPurple);
  background: var(--veryVeryLightPurple);
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    height: calc(100% - 182px);
  }
}

@media screen and (max-width: 767px) {
  .container {
    height: calc(100% - 182px);
  }
}

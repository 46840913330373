.container {
  border: var(--border);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--grey);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 0.5rem;

  border-radius: 5px;
  border: 1px solid var(--red);
  background: var(--errorBgRed);
  margin: 1rem 0;
}

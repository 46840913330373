.container {
  padding: 48px 74px;
  border-radius: 0px 0px 10px 10px;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 24px 18px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 24px 18px;
  }
}

.container {
  padding: 24px 0;
  margin: 24px 0;
  width: 100%;
  overflow-x: hidden;
  cursor: pointer;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header > div:nth-child(1) > h2 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
}

.header > div:nth-child(1) > p {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.header > div:nth-child(2) {
  display: flex;
  align-items: center;
  gap: 24px;
}

.header > div:nth-child(2) > span {
  width: 40px;
  height: 40px;
  border: var(--border);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid var(--purple);
  cursor: pointer;
  transition: var(--threeSecTransition);
}

.header > div:nth-child(2) > span:hover {
  background: var(--purple);
}

.header > div:nth-child(2) > span > svg {
  width: 24px;
  height: 24px;
}

.courseSection {
  display: flex;
  align-items: center;
  gap: 24px;
  overflow-x: auto;
  width: 100%;
  margin: 24px 0;
  scroll-behavior: smooth;
  padding: 1rem 0;
}

.courseSection {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.courseSection::-webkit-scrollbar {
  display: none;
}
.courseSection > div {
  width: 600px;
  flex-shrink: 0;
  flex-basis: 600px;
  box-sizing: border-box;
  transition: all 0.3s ease;
}

.courseSection > div:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  padding: 40px 24px 24px 24px;
}

.container > div:nth-child(1) {
  display: flex;
  align-items: center;
  gap: 24px;
}

.container > div:nth-child(1) div:nth-child(1) {
  margin-right: 0.5rem;
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

.container > div:nth-child(1) > div:not(.container > div:nth-child(1)) > span {
  border-radius: 10px;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid var(--teamCardBorderPurple);
}

.container
  > div:nth-child(1)
  > div:not(.container > div:nth-child(1))
  > span
  > span:nth-child(1) {
  cursor: pointer;
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.container
  > div:nth-child(1)
  > div:not(.container > div:nth-child(1))
  > span
  > span:nth-child(2) {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--purple);
  border-radius: 50%;
  color: var(--white);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.filter {
  position: relative;
}

.dropdown {
  border-radius: 10px;
  background: var(--white-body-text, #fff);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  width: 280px;
  max-height: 320px;
  padding: 16px;
  overflow-y: auto;
  position: absolute;
  z-index: 120;
  left: 0;
  top: 40px;
  user-select: none;
  animation: appear 0.3s ease-in-out;
}

.option {
  display: block;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
}

.clearAll {
  color: var(--purple);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  cursor: pointer;
  margin-left: 2rem;
}

.container > div:nth-child(2) {
  width: 426px;
  position: relative;
}

.container > div:nth-child(2) > input {
  width: 100%;
  padding: 12px 24px;
  border-radius: 10px;
  border: 1px solid var(--foundation-grey-g-50, #d8d8d8);
  background: var(--white-body-text, #fff);
  color: var(--foundation-grey-g-900, #2e2e2e);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  outline: none;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    flex-wrap: wrap;
    padding: 24px 16px;
  }

  .container > div:nth-child(1) > div:nth-child(1) {
    flex-basis: 100%;
    margin-bottom: 0.5rem;
  }

  .container > div:nth-child(1) > div:nth-child(1),
  .container
    > div:nth-child(1)
    > div:not(.container > div:nth-child(1))
    > span {
    font-size: 14px;
    line-height: 23px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    flex-wrap: wrap;
    padding: 24px 16px;
  }

  .container > div:nth-child(1) {
    flex-wrap: wrap;
    gap: 16px;
  }

  .container > div:nth-child(1) > div:nth-child(1) {
    flex-basis: 100%;
  }

  .container > div:nth-child(1) > div:nth-child(1),
  .container
    > div:nth-child(1)
    > div:not(.container > div:nth-child(1))
    > span {
    font-size: 14px;
    line-height: 23px;
    padding: 12px;
  }
}
